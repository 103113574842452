import React from 'react';
// import { StateContext } from '../stateContext';
import notification2 from '../assets/images/notification2.PNG'
import displayCodeScreenshot from '../assets/images/displayCodeModalScreenshot.png'
import { Smartphone, Tablet, Monitor } from 'react-feather';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function HowItWorksPage() {
  const navigate = useNavigate();

  const steps = [
    {title: "Find a Device",
    content: 
      <div className="flex flex-col items-center">
        <p>Use almost any device that can connect to the internet and display a webpage. This can be a tablet, computer, or even a smart TV.</p>
        <div className="flex flex-row items-center justify-center w-full h-auto">
           <Smartphone className=" w-20 h-20 inline-block ml-1" />
          <Tablet className=" w-20 h-20 inline-block ml-1"/>
          <Monitor className=" w-20 h-20 inline-block ml-1"/>
        </div>
        <a href="/devices" target="_blank" className="btn">Learn about display devices</a>
      </div>
    },
    {title: "Schedule messages and events from your dashboard",
    content: 
    <div className="flex flex-col items-center">
      <p>Use the dashboard to schedule messages and events to show up on the display. You can also remotely manage the display's settings such as uploading family photos to display or enabling the weather.</p>
      <a href="/dashboard-overview" target="_blank" className="btn">Learn more about the dashboard</a>
      </div>
    },
    {title: "Navigate to your unique display page",
    content: 
      <div className="flex flex-col items-center">
        <p>Each care recipient display is tied to caregiver accounts through a unique 6 digit code. You'll navigate to <a className="link" href="/display" target="_blank">caregivingconnect.com/display</a> and enter this code.</p>
        <img className="w-full mx-auto rounded-xl my-4" src={displayCodeScreenshot} alt="display code input screenshot"/>
        <a href="/display-overview" target="_blank" className="btn">Learn more about the display</a>
      </div>
    },
    {title: "Get mobile notifications",
    content: 
    <div className="flex flex-col items-center">
      <p>If you follow <a className="link" href="/app-overview" target="_blank">these</a> instructions to download our mobile app, you'll receive notifications when care recipients interact with their display. This means you are getting two-way communication through the simplest interface imaginable.</p>
      <img className="w-full mx-auto rounded-xl my-4" src={notification2} alt="notification screenshot"/>
      <a href="/app-overview" target="_blank" className="btn">Learn more about the app</a>
    </div>
    },
  ]

  return (
  <div className="flex flex-col justify-center mx-auto w-full lg:w-3/4 gap-2 p-4">
    <Helmet>
        <title>How Caregiving Connect Works</title>
        <meta name="description" content="Learn about the Caregiving Connect display, dashboard, and app." />
        <meta property="og:title" content="How Caregiving Connect Works" />
        <meta property="og:description" content="Learn about the Caregiving Connect display, dashboard, and app." />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="How Caregiving Connect Works" />
        <meta name="twitter:description" content="Learn about the Caregiving Connect display, dashboard, and app." />
    </Helmet>
    <p className="text-3xl font-bold text-center pb-4">How it works</p>
    <ul className="timeline timeline-vertical timeline-compact md:hidden overflow-scroll">
      {steps?.map((step, index) => (
          <li key={step.title} className="">
              {index !== 0 && <hr />}
              <div className={index % 2 === 0 ? "timeline-end md:timeline-start my-4 md:my-0 timeline-box" : "timeline-end timeline-box"}>
                <h3 className="text-lg w-full font-bold">{step.title}</h3>
                
                {step.content}
              </div>
              <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
              </div>
              {index !== steps.length-1 && <hr />}
          </li>
      ))}
    </ul>
    <ul className="timeline timeline-vertical hidden md:block overflow-scroll">
      {steps?.map((step, index) => (
          <li key={step.title} className="">
              {index !== 0 && <hr />}
              <div className={index % 2 === 0 ? "timeline-end md:timeline-start my-4 md:my-0 timeline-box" : "timeline-end timeline-box"}>
                <h3 className="text-lg w-full font-bold">{step.title}</h3>
                
                {step.content}
              </div>
              <div className="timeline-middle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
              </div>
              {index !== steps.length-1 && <hr />}
          </li>
      ))}
    </ul>
    {/* <button onClick={()=>navigate('/pricing')} className="btn w-auto mx-auto">See our low cost tiers</button> */}
  </div>
  );
}

export default HowItWorksPage;