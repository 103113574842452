import React from 'react';

const CheckoutCancelPage = () => {

    const handleCloseTab = () => {
        window.close(); // Close the current tab
    }

    return (
        <div className="w-2/3 p-4 mx-auto flex flex-col items-center justify-center border rounded-lg shadow-lg">
            <div className="text-center">
                <h1 className="text-2xl font-bold">Welcome Back!</h1>
                <p className="text-lg mt-2">Your checkout was cancelled.</p>
            </div>

            <div className="my-6 flex flex-col items-center">
                <button className="btn btn-primary mt-4" onClick={handleCloseTab}>
                    Click to Close
                </button>
            </div>
        </div>
    );
};

export default CheckoutCancelPage;