import React from 'react';
// import { StateContext } from '../stateContext';


function AboutPage() {
  return (
<div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4 text-center">Privacy Policy</h1>
      <p className="mb-4">
        Podtube is committed to protecting your privacy and ensuring the security of
        your personal information. This Privacy Policy outlines how we collect, use, and safeguard
        your data when you use our services.
      </p>
      <h2 className="text-xl font-semibold mb-2">Information We Collect</h2>
      <p className="mb-4">
        We collect your email address only for authentication purposes.
      </p>
      <h2 className="text-xl font-semibold mb-2">How We Use Your Information</h2>
      <p className="mb-4">
        We do not use this information in any way other than the necessary steps to provide you with a secure account.
      </p>
      <h2 className="text-xl font-semibold mb-2">Data Security</h2>
      <p className="mb-4">
        We implement industry-standard security measures to protect your personal information and
        ensure data confidentiality. This includes encryption, access controls, and regular
        security audits.
      </p>
      <h2 className="text-xl font-semibold mb-2">Data Retention</h2>
      <p className="mb-4">
        We retain your data only as long as necessary to fulfill the purposes outlined in this
        Privacy Policy, or as required by law. Requested podcasts are deleted after two weeks.
      </p>
      <h2 className="text-xl font-semibold mb-2">Third-Party Services</h2>
      <p className="mb-4">
        We use third-party services such as Google APIs to
        hand authentication and file storage.
        However, we do not share your media contents, account information, or
        usage data with anyone, including third parties for marketing or advertising purposes.
      </p>
      <h2 className="text-xl font-semibold mb-2">Changes to this Policy</h2>
      <p className="mb-4">
        We reserve the right to modify this Privacy Policy at any time. Any changes will be
        communicated to you through our platform or via email. Please review this policy
        periodically for updates.
      </p>
    </div>
  );
}

export default AboutPage;