import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Share, UserPlus, Heart, MessageSquare, Zap, Calendar, Bell, Send } from 'react-feather';
import { NewspaperIcon, SunIcon, PhotoIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import CCDashboard from '../assets/images/CCDashboard.png'
import clientMessages from '../assets/images/clientMessages.png'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function DashboardOverviewPage() {
  const navigate = useNavigate()
  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 p-4">
    <Helmet>
      <title>Caregiving Connect | Dashboard Overview</title>
      <meta name="description" content="Use your personalized dashboard to communicate with your loved one from anywhere." />
      <link rel="canonical" href={window.location.href} />
      <meta property="og:title" content="Caregiving Connect | Display Overview." />
      <meta property="og:description" content="Use your personalized dashboard to communicate with your loved one from anywhere." />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Caregiving Connect | Display Overview." />
      <meta name="twitter:description" content="Use your personalized dashboard to communicate with your loved one from anywhere." />
    </Helmet>
    <p className="text-2xl font-bold text-center">The Caregiving Connect Dashboard</p>
    <div className="flex flex-col justify-center mx-auto">
      <p className="text-center text-xl">Learn about the dashboard that drives the display.</p>
      <div className="flex flex-row items-center justify-center pt-4">
        <div className="mockup-window border bg-base-300 mx-auto">
          <img src={CCDashboard} className="" alt="demo display"></img>
        </div>
        <div className="divider divider-horizontal hidden lg:flex h-3/4 my-auto"></div>
        <img src={clientMessages} className="hidden lg:flex h-96" alt="mobile dashboard"/>
      </div>
    </div>
    <p className="text-lg text-center py-8">Whatever your caregiving situation, the Caregiving Connect dashboard makes it easy to set up multiple displays and manage access for multiple caregivers in your family or on your team.</p>
    <div className="flex flex-col justify-center mx-auto">
      <p className="text-2xl text-center">Manage your family or team</p>
      <div className="flex flex-col md:flex-row justify-center">
          {/* Feature 1 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
              <Users className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Caregiving is better together</h3>
            <p className="text-md text-left">Invite your family members or teammates to join your group to share access to multiple displays.</p>
          </div>
          {/* Feature 2 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <UserPlus className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Manage your team</h3>
            <p className="text-md text-left">As the group owner, you have full control what displays each group member can access, and full visibility into every message, event, or update that occurs in your group.</p>
          </div>
          {/* Feature 3 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Heart className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Share the love</h3>
            <p className="text-md text-left">
              Create multiple unique displays and assign them any or all caregivers in your group. 
            </p>
          </div>
        </div>
    </div>
    <div className="flex flex-col justify-center mx-auto">
      <p className="text-2xl text-center">Customize Individual Displays</p>
      <p className="text-xl text-center">No matter how many displays you have in your group, you can always update them individually.</p>
      <div className="flex flex-col md:flex-row justify-center">
          {/* Feature 1 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
              <MessageSquare className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Send Messages & Events</h3>
            <p className="text-md text-left">Schedule or send message and events to the display immediately.</p>
          </div>
          {/* Feature 2 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Zap className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">View Recent Display Events</h3>
            <p className="text-md text-left">View message acknowledgements and get insights into the behavior of your loved ones as your view a record of every interaction they have with the display.</p>
          </div>
          {/* Feature 3 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <PhotoIcon className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Add Fun Content</h3>
            <p className="text-md text-left">
              Spice your loved one's routine by adding photos, weather, and more to their lives from anywhere. 
            </p>
          </div>
        </div>
    </div>
    <div className="flex flex-col justify-center mx-auto">
      <p className="text-2xl text-center">Stay informed from anywhere.</p>
      <p className="text-xl text-center">You can't be there 24/7, so let Caregiving Connect fill in.</p>
      <div className="flex flex-col md:flex-row justify-center">
          {/* Feature 1 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
              <Calendar className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Scheduled items pick up where you leave off.</h3>
            <p className="text-md text-left">As much as you must love reminding them of little things throughout the day, you can count on Caregiving Connect to continue displaying scheduled events and reminders throughout the day.</p>
          </div>
          {/* Feature 2 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Send className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Send quick messages from anywhere</h3>
            <p className="text-md text-left">When time is of the essence, the Caregiving Connect mobile app makes it easy to quickly send a new message.</p>
          </div>
          {/* Feature 3 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Bell className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Receive mobile and email notifications</h3>
            <p className="text-md text-left">
              Get peace of mind through little reminders throughout the day  with every tap or click of the display, letting you know that your loved one is doing ok. 
            </p>
          </div>
        </div>
      <button className="btn btn-primary mt-2 w-auto mx-auto" onClick={()=>navigate('/display-overview')}>Learn about the display</button>

    </div>
  </div>
  );
}

export default DashboardOverviewPage;