import React, { useState, useContext } from 'react';
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary
import apiClient from '../utils/apiClient';
import { StateContext } from '../stateContext';

const FeedbackReachOutModal = ({ type, onClose }) => {
    const { profile, user } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const {setToastData} = useContext(StateContext);

    const [formData, setFormData] = useState({
        name: profile?.name || '',
        email: profile?.email || '',
        comments: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true)

        try {
            const payload = {
                formData,
                type,
            }
            if (profile) {
                payload.userID = user.uid
            }
          const response = await apiClient.post('/feedback', payload);
          setIsLoading(false)
          onClose(); // Close the modal after saving
          setToastData({ text: `Message sent successfully.`, show: true });
      } catch (error) {
          console.error('Error Sending message:', error);
          setIsLoading(false)
          onClose()
          setToastData({ text: "Sending message failed, please send us an email at support@caregivingconnect.com.", show: true });
          // Handle errors here (e.g., show error message to user)
      } finally {
            console.log(formData);
            onClose(); // Close modal after submission
        }

    };

    const types = {
        feedback: {
            title: "Feedback",
            description: "We value your feedback. Please let us know your thoughts and experiences about using our platform."
        },
        professionalReachOut: {
            title: "Reach Out to Us",
            description: "Fill out the form below to start a conversation about how Caregiving Connect could help out your business."
        },
        communityReachOutFromPricingPage: {
            title: "Reach Out to Us",
            description: "Fill out the form below to start a conversation about how Caregiving Connect could help out your care community."
        },
        accountFeedback: {
            title: "accountFeedback",
            description: "We'd love your feedback and promise this will be seen by a decision maker. Please let us know your thoughts and experiences about using our platform."

        }
    };

    return (
        <div className="modal modal-open">
            <div className="modal-box relative">
                <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</label>
                <h3 className="text-lg font-bold">{types[type].title}</h3>
                <p className="text-sm mb-4">{types[type].description}</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Name</span>
                        </label>
                        <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="input input-bordered" required />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Email</span>
                        </label>
                        <input type="email" name="email" value={formData.email} onChange={handleInputChange} className="input input-bordered" required />
                    </div>
                    <div className="form-control">
                        <label className="label">
                            <span className="label-text">Comments</span>
                        </label>
                        <textarea name="comments" value={formData.comments} onChange={handleInputChange} className="textarea textarea-bordered" required></textarea>
                    </div>
                    <div className="modal-action">
                        <button type="submit" className="btn">{isLoading ? <div className="loading loading-spinner loading-sm" /> : "Submit"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FeedbackReachOutModal;