import React, { useState, useEffect, useContext } from 'react';
// import PricingTable from '../components/PricingTable'
// import {ReactComponent as KomIcon} from '../../src/assets/gold_KOM.svg'
// import {ReactComponent as CCIcon} from '../../src/assets/CCLogo.png'
import storage from "../utils/local_storage"
import { useNavigate } from 'react-router-dom';
import {ReactComponent as CurveArrowIcon} from '../assets/curve-arrow.svg'
import apiClient from '../utils/apiClient'; // Assume this is configured to work with your backend API
import { StateContext } from '../stateContext';
import { Monitor, Bell, Menu, Phone, Tablet, Clock, Camera, Calendar, Sun, User, Share2, Smartphone } from 'react-feather';
// import displaydemo from '../assets/images/displaydemo.png'
import laptopDisplayPage from '../assets/images/customMessageAcks.png'
// import IpadDisplayPage from '../assets/images/IpadDisplayPage.png'
import CCLogo from '../assets/CCLogo.png'
import daughter_and_mother from '../assets/images/daughter_and_mother.jpeg'
import videoCall from '../assets/images/videoCall.jpeg'
import clientMessages from '../assets/images/clientMessages.png'
import notification1 from '../assets/images/notification1.PNG'
// import notification2 from '../assets/images/notification2.PNG'
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary
import { NewspaperIcon, SunIcon, PhotoIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import local_storage from '../utils/local_storage';
import { Helmet } from 'react-helmet';

function HomePage() {
  const hasLoggedIn = local_storage.getItem("hasLoggedIn")
  const {setToastData} = useContext(StateContext);
  // const token = localStorage.getItem('Token');
  const [email, setEmail] = useState('');
  const [pressedButtons, setPressedButtons] = useState([]);

  const navigate = useNavigate();
  const { profile, user, signOut, signIn } = useUser();

  // const handleDashboardButtonClick = () => {
  //   navigate('/dashboard'); // Replace '/path-to-navigate' with your route
  // };

  const speak = (text) => {
      const utterance = new SpeechSynthesisUtterance(text);
      utterance.rate = .75
      window.speechSynthesis.speak(utterance);
  };

  const handleButtonPress = (text) => {
    // speak ("Response sent")
    // setToastData({text:"Response sent", show:true})
    setPressedButtons([...pressedButtons, text])
  }

  const DashboardButton = () => {
    return (
      <button className="btn btn-secondary text-lg text-black" onClick={()=>navigate('/dashboard')}>Go To Dashboard</button>
    )
  }

    const LogInButton = () => {
    return (
      <div>
        <button className="btn btn-secondary text-lg" onClick={()=>navigate('/login')}>{hasLoggedIn ? "Log In" : "Sign Up"}</button>
      </div>
    )
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

  const handleEmailSubmit = async () => {
    // event.preventDefault();
    if (!validateEmail(email)) {
      setToastData({text:"Please submit a valid email", show:true})
      return
    }

    try {
      const response = await apiClient.post(`/emails`, { email });
      setToastData({text:"Thank you for connecting with Textractify", show:true})
      setEmail(''); // Clear the input after successful submission
    } catch (error) {
      console.error('Error submitting email:', error);
      alert('Error submitting email. Please try again later.');
    }
  };

  return (
    <div className="flex flex-col w-screen items-center justify-center">
      <Helmet>
        <title>Caregiving Connect | Care for your loved ones from anywhere.</title>
        <meta name="description" content="Use our always on display on any device to send messages to your loved ones." />
        <meta property="og:title" content="Caregiving Connect | Care for your loved ones from anywhere." />
        <meta property="og:description" content="Use our always on display on any device to send messages to your loved ones." />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Caregiving Connect | Care for your loved ones from anywhere." />
        <meta name="twitter:description" content="Use our always on display on any device to send messages to your loved ones." />
      </Helmet>
      {/* Row 1 */}
      <div className="flex flex-col lg:flex-row justify-center min-h-1/2 max-w-full items-center mt-4 lg:px-16 w-full">
        <div className="flex flex-col text-center m-4 w-full md:w-3/4 lg:w-1/2">
          <div className="flex flex-col items-center justify-center mx-auto pb-4">
            <img className="w-24 " src={CCLogo} alt="CC logo"></img>
            {/* <p className="text-4xl text-center font-bold">Caregiving Connect</p> */}
          </div>
          <h1 className="text-2xl lg:text-4xl text-center font-bold px-4">Care for your loved ones when you can't be there</h1>
          <h2 className="text-lg lg:text-2xl text-center px-4">through the <span className="bg-secondary  font-semibold rounded-lg px-2">simplest</span> interface imaginable.</h2>
        </div>
          <div className="w-full md:w-3/4 lg:w-1/2 p-4 mx-auto">
            <img 
              src={daughter_and_mother} 
              className="rounded-xl" 
              alt="mother and daughter looking at computer"/>
          </div>
        </div>
        <div className="flex flex-col text-center items-center justify-center w-full lg:w-1/2">
            {/* <button className="btn btn-normal text-lg w-sm mx-auto my-4" onClick={() =>  navigate('/demo', { state: { demoData } })}>Try it out</button> */}
          <div className="w-full flex-col text-center">
            {/* <p className="text-xl lg:text-3xl">Give them their independence back.</p> */}
          </div>
        <p className="text-lg px-4">Caregiving Connect turns almost any device into an always-on message, photo, and video call interface so you can interact with anyone, anytime. Set up the display once and manage it all from the app.</p>
        <div className="flex flex-row items-center gap-4 mx-auto pt-4">
          <button onClick={() => window.open('/display/demo', '_blank')} className="btn btn-primary text-lg text-white">Try a Demo</button>
          {<p className="text-lg">or</p>}
          {user ? 
            <div className="flex flex-col justify-center items-center">
              <DashboardButton/>
            </div> : 
            <div className="flex flex-col justify-center items-center">
              <LogInButton/>
            </div>}
          </div>

        </div>
      <div className="divider"/>
      <div className="flex flex-col gap-12 w-full px-4">
        <div className="flex flex-col lg:flex-row items-center justify-center min-h-96">
          <div className="flex flex-col text-center justify-center">
          <p className="text-2xl lg:text-3xl text-center pb-2">Put the family computer to work as an always-on message display.</p>
          <p className="text-md lg:text-xl text-center pb-2">Set up once by navigating to your unique display page and start sending messages, photos, and video calls to your loved ones any time.</p>
          </div>
          <img src={laptopDisplayPage} onClick={() => window.open('/display/demo', '_blank')} className="hover:cursor-pointer w-full lg:w-3/5" alt="demo display"></img>
        </div>
        <div className="flex flex-col items-center justify-center px-4">
        <p className="text-3xl font-bold text-center pb-4">How it works</p>
        {/* <p className="text-xl">Use almost any old device.</p> */}
        <div className="flex flex-col md:flex-row justify-center">
          {/* Feature 1 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
              <Monitor className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Open the display page</h3>
            <p className="text-md text-left">Open the CaregivingConnect <a className="link" href='/display' target="_blank">display page</a> on any device with a large screen.</p>
          </div>
          {/* Feature 2 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Smartphone className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Download the app</h3>
            <p className="text-md text-left">Use the app or website to add messages, events, photos and more to the display.</p>
          </div>
          {/* Feature 3 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Share2 className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Connect from anywhere</h3>
            <p className="text-md text-left">
              Let your scheduled items do the reminding for you.
              Open the app and send real-time messages. 
              Get notified when your loved ones interact with your messages or request calls.
            </p>
          </div>
        </div>
        <button onClick={()=>navigate('/how-it-works')} className="btn md:btn-lg">Learn more about how it works</button>
      </div>
        <div className="flex flex-col md:flex-row items-center justify-center rounded-xl p-4 gap-0  min-h-96">
          <div className="flex flex-col items-center  md:w-1/2 justify-center md:order-last">
           <p className="text-2xl lg:text-3xl  text-center">Send and schedule messages and events.</p>
           <p className="text-lg lg:text-xl text-center">Let Caregiving Connect do the reminding for you.</p>
           {/* <p className="text-lg lg:text-xl w-3/4 text-center">Choose your response buttons to get useful answers.</p> */}
          </div>
          <div className="flex flex-col  md:flex-row w-full justify-evenly mx-auto gap-4 p-2 md:w-1/2 ">
              {/* <div className="flex flex-col justify-center text-center w-full ">
                <div className="flex flex-col items-center  p-4 gap-4 border-2 rounded-xl">
                  <p className="text-2xl">Send messages</p>
                  <div className="flex flex-col justify-between w-full items-center bg-black text-white rounded-xl p-2 text-3xl">
                    <div className="flex flex-col my-auto justify-between item-stretch h-full w-full hover:cursor-pointer text-3xl">
                      <div className="badge bg-blue-400 rounded-lg border-none text-lg">Message</div>
                      <p className="whitespace-pre-line text-2xl lg:text-3xl text-left">Heading home!</p>
                      <p className="italic text-right text-xl lg:text-2xl">sent 15 minutes ago</p>
                      {!pressedButtons.includes("messageButton") && <div className="flex flex-row gap-2 w-full">
                        <button className="btn rounded-xl btn-normal lg:btn-lg flex-1" onClick={() => handleButtonPress("messageButton")}>Ok</button>
                      </div>}
                      </div>
                  </div>
                </div>
              </div> */}
              <div className="flex flex-col justify-center text-center w-full max-w-96">
                <div className="flex flex-col items-center gap-4  rounded-xl">
                  {/* <p className="text-2xl ">Set reminders</p> */}
                  <div className="flex flex-col justify-between w-full items-center bg-black text-white rounded-xl p-2 text-3xl">
                    <div className="flex flex-col my-auto justify-between h-full w-full text-3xl">
                      <div className="badge bg-red-400 rounded-lg border-none text-lg">Reminder</div>
                      <p className="whitespace-pre-line text-2xl lg:text-4xl text-left">How are you feeling today mom?</p>
                      <p className="italic text-right text-xl lg:text-2xl">5 minutes ago</p>
                      {!pressedButtons.includes("reminderButton") && <div className="flex flex-row gap-2 w-full">
                        <button className="btn rounded-xl btn-normal lg:btn-lg btn-success flex-1" onClick={() => handleButtonPress("reminderButton")}>Good</button>
                        <button className="btn rounded-xl btn-normal lg:btn-lg btn-error flex-1" onClick={() => handleButtonPress("reminderButton")}>Bad</button>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-col items-center text-center w-full  border-2 rounded-xl p-4 gap-4">
                <p className="text-2xl">Schedule events</p>
                 <div className="flex flex-col justify-between w-full items-center bg-black text-white rounded-xl p-2">
                  <div className="flex flex-col w-full hover:cursor-pointer">
                  <div className="badge bg-yellow-400 rounded-lg border-none text-lg">Event </div>
                  <p className="font-bold text-2xl lg:text-3xl text-left">Susan picks you up for a hair cut</p>
                  <p className="text-xl lg:text-2xl whitespace-pre-line text-left">Be ready to go to dinner afterwards </p>
                  <div className="flex flex-row justify-between items-end gap-2">
                    <div className="flex flex-col justify-left">
                      <div className="flex flex-row items-center gap-2">
                        <Calendar/>
                        <p className="font-bold text-xl lg:text-2xl"> May 20, 2024</p>
                      </div>
                      <div className="flex flex-row gap-2 items-center">
                        <Clock/>
                        <p className="font-bold text-xl lg:text-2xl"> 4:52 PM</p>
                      </div>
                    </div>
                  <p className="text-right text-xl lg:text-2xl italic">in 1 day</p>
                  </div>
                  {!pressedButtons.includes("eventButton") && <div className="flex flex-row gap-2 w-full">
                    <button className="btn rounded-xl btn-normal lg:btn-lg flex-1" onClick={() => handleButtonPress("eventButton")}>Ok</button>
                  </div>}
                </div>
              </div>
            </div> */}
            </div>
          </div>
        <div className="flex flex-col md:flex-row items-center justify-center rounded-xl gap-4  min-h-96">
          <div className="flex flex-col items-center justify-center">
           <p className="text-2xl lg:text-3xl  text-center">Stay connected throughout the day across any distance.</p>
           <p className="text-lg lg:text-xl w-full lg:w-3/4 text-center">Receive mobile notifications with message responses, call requests, audio messages, and more.</p>
          </div>
          <div className=" flex flex-col justify-center items-center p-2 gap-4 w-full md:w-3/4 mx-auto lg:w-1/2">
              <img src={notification1} className=" rounded-xl" alt="notification request screenshot"/>
              {/* <img src={notification2} className=" rounded-xl" alt="notification request screenshot"/> */}
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center rounded-xl gap-4  min-h-96">
          <div className="flex flex-col items-center justify-center md:w-1/2">
           <p className="text-2xl lg:text-3xl  text-center">Drop in with a video call any time.</p>
           <p className="text-lg lg:text-xl w-full lg:w-3/4 text-center">No need to teach them how to answer, it just works.</p>
          </div>
          <div className="md:order-first flex flex-col justify-center items-center p-2 gap-4 w-full md:w-3/4 mx-auto lg:w-1/2">
              <img src={videoCall} className=" rounded-xl" alt="notification request screenshot"/>
              {/* <img src={notification2} className=" rounded-xl" alt="notification request screenshot"/> */}
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center rounded-xl gap-4 min-h-96">
          <div className="flex flex-col items-center justify-center md:order-first">
            <p className="text-2xl lg:text-3xl text-center">The Caregiving Connect app manages it all, from anywhere.</p>
            <ul className="list-disc text-xl p-4">
              <li>Send messages</li>
              <li>Schedule events</li>
              <li>Drop in with a video call</li>
              <li>Respond to call requests</li>
              <li>Listen to audio messages</li>
              {/* <li>View recent display interactions</li> */}
            </ul>
            <button className="btn mx-auto md:btn-lg" onClick={()=>navigate('dashboard-overview')}>Learn more about the dashboard</button>
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-center justify-center">
            <img 
              src={clientMessages} 
              className="w-1/2 md:w-2/3 lg:w-1/2" 
              alt="dashboard messages"
              />
            </div>
        </div>
          {/* <button className="btn mx-auto md:btn-lg hidden md:block" onClick={()=>navigate('dashboard-overview')}>Learn more about the dashboard</button> */}
        <div className="flex flex-col lg:flex-row items-center justify-center rounded-xl gap-4 min-h-96 ">
          <div className="flex flex-col items-center justify-center gap-4">
            <p className="text-2xl lg:text-3xl  text-center">Enrich your loved one's day with photos, weather, and more interesting content.</p>
            <p className="text-lg lg:text-xl  text-center">Brighten their day while encouraging their continued interaction with your messages on the display.</p>
            <button className="btn mx-auto md:btn-lg" onClick={()=>navigate('display-overview')}>Learn more about the display</button>
          </div>
          <div className="flex flex-row lg:flex-col items-center justify-evenly w-full gap-4 lg:w-1/2">
              {/* Feature 1: Photos */}
              <div className="border bg-primary text-white text-center p-4 w-24 h-24 md:w-40 md:h-40 rounded-full flex flex-col items-center justify-center">
                  <PhotoIcon className="w-12 h-12 md:mb-2" />
                  <h3 className="md:text-lg font-semibold">Photos</h3>
              </div>
              {/* Feature 2: Weather */}
              <div className="border bg-primary text-white text-center p-4 w-24 h-24 md:w-40 md:h-40 rounded-full flex flex-col items-center justify-center">
                  <Sun className="w-12 h-12 md:mb-2" />
                  <h3 className="md:text-lg font-semibold">Weather</h3>
              </div>
              {/* Feature 3: News Headlines */}
              <div className="border bg-primary text-white text-center p-4 w-24 h-24 md:w-40 md:h-40 rounded-full flex flex-col items-center justify-center">
                  <NewspaperIcon className="w-12 h-12 md:mb-2" />
                  <h3 className="md:text-lg font-semibold">Headlines</h3>
              </div>
          </div>
        </div>
      </div>
        
      <div className="hidden flex flex-col items-center rounded-xl gap-4">
        <p className="text-2xl lg:text-3xl  text-center">All through an interface compatible with a wide spectrum of conditions.</p>
        <div className="flex flex-col text-lg justify-start gap-2">
          <div className="flex flex-row items-center gap-2">
            <CheckCircleIcon className="w-6 h-6 flex-shrink-0"/>
            <p className="">Large font</p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <CheckCircleIcon className="w-6 h-6 flex-shrink-0"/>
            <p className="">No menus</p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <CheckCircleIcon className="w-6 h-6 flex-shrink-0"/>
            <p className="">No scrolling</p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <CheckCircleIcon className="w-6 h-6 flex-shrink-0"/>
            <p className="">Large buttons</p>
          </div>
        </div>
      </div>
      {/* <div className="divider"></div>
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-4">
          <div className="w-full flex-col justify-center items-center gap-4">
            <p className="text-3xl text-center font-bold">Put important information front and center</p>
            <p className="text-xl text-center pb-4">and get notifications when it is seen.</p>
            
              <p className="text-3xl text-center font-bold pt-8">Plus display delightful content</p>
              <p className="text-xl text-center pb-4">to encourage interactions and brighten their day.</p>    
          </div>
        </div>
      </div> */}
       <div className="hidden divider"></div>
      <div className="hidden flex flex-col items-center justify-center px-4">
        <p className="text-4xl text-center pb-4">Benefits</p>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-4 justify-center rounded-lg w-full">
          {/* Feature 1 */}
          <div className="text-left max-w-xs rounded-lg">
            <User className="w-12 h-12 inline-block mb-4" />
            <h3 className="text-lg font-semibold">Increased Independence</h3>
            <p className="text-md">through timely information displayed on the screen.</p>
          </div>
          {/* Feature 2 */}
          <div className=" text-left max-w-xs rounded-lg">
            <Monitor className="w-12 h-12 inline-block mb-4" />
            <h3 className="text-lg font-semibold">Reduced Reminders</h3>
            <p className="text-md">Schedule upcoming or recurring events or tasks and count on them popping up to remind your loved ones on your behalf.</p>
          </div>
          {/* Feature 3 */}
          <div className=" text-left max-w-xs rounded-lg">
            <Share2 className="w-12 h-12 inline-block mb-4" />
            <h3 className="text-lg font-semibold">Greater Connection</h3>
            <p className="text-md">When texting becomes difficult, let CaregivingConnect be your interface for sending quick messages to your loved ones.</p>
          </div>
          {/* Feature 4 */}
          <div className=" text-left max-w-xs rounded-lg">
            <Bell className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-lg font-semibold">Higher Safety</h3>
            <p className="text-md">At some point, even dialing a phone can become difficult. Caregiving Connect provides simple interface to send call requests to the caregiver.</p>
          </div>
          
        </div>
      </div>
      {/* <div className="divider"></div> */}
      
      {/* <div className="divider"></div>
      <div className="container mx-auto px-4">
        <p className="text-3xl font-bold text-center pb-4">Who benefits?</p>
        <div className="flex flex-col md:flex-row justify-center">
          <div id="families" className="">
            <p>Families</p>
          </div>
          <div id="professionals" className="">
            <p>Professionals</p>
          </div>
        </div>
      </div> */}
      <div className="divider hidden"></div>
      <div className="hidden container mx-auto px-4">
        <p className="text-2xl font-bold text-center pb-4">Features</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6 justify-items-center rounded-lg">
          {/* Feature 1 */}
          <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
              <Monitor className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-lg font-semibold">Always on Display</h3>
            <p className="text-sm">Displays the time, date, day of the week, messages, events, photos and more!</p>
          </div>
          {/* Feature 2 */}
          <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
            <Menu className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-lg font-semibold">Simplest Interface Imaginable</h3>
            <p className="text-sm">No scrolling, no menus, just the information you want to get in front of your loved ones.</p>
          </div>
          {/* Feature 3 */}
          <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
            <Bell className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-lg font-semibold">Interaction Notifications</h3>
            <p className="text-sm">Get push notifications when your loved ones interact with your messages.</p>
          </div>
          {/* Feature 4 */}
          <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
            <Phone className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-lg font-semibold">Call Requests</h3>
            <p className="text-sm">Large "Call Me" buttons make it easy to for your loved ones to request calls that fit in to your schedule.</p>
          </div>
          <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
            <Clock className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-lg font-semibold">Scheduled Messages</h3>
            <p className="text-sm">Schedule unlimited messages, reminders, and events to encourage healthy habits.</p>
          </div>
          <div className="border bg-base-100 text-center p-8 max-w-xs rounded-lg">
            <Camera className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-lg font-semibold">Entertainment content</h3>
            <p className="text-sm">Caregiving Connect also displays jokes, news, and photos to encourage frequent interactions with your messages.</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-4 pt-8">

      <button onClick={() => window.open('/display/demo', '_blank')} className="btn btn-primary text-lg text-white">Try a Demo</button>
      {<p className="text-lg">or</p>}
    {user ? 
      <div className="flex flex-col justify-center items-center">
        <DashboardButton/>
      </div> : 
      <div className="flex flex-col justify-center items-center">
        <LogInButton/>
      </div>}
        </div>
      <div className="divider"></div>
      <div className="container mx-auto px-4 pb-8 w-full md:w-3/4 lg:w-1/2">
        <p className="text-2xl font-bold text-center pb-4">Our Story</p>
        <p className="indent-8">We started Caregiving Connect after experiencing the need to balance caring for our aging parents with continuing 
          to live our busy lives. Due to various medical conditions, using standard forms of communication such as texting 
          and calling became difficult, so we resorted to using a whiteboard to write reminders or schedules for the next day. 
          We found this worked well but updating manually became cumbersome, despite living right next to our parents. We built Caregiving Connect to digitize our digital whiteboard, allowing us to communicate remotely.
          This tool keeps our family in sync and reduces the stress of constant reminders and forgetfulness.</p>
      </div>
    </div>
    )
  ;
}

export default HomePage;