import React from 'react';
// import { StateContext } from '../stateContext';


function AboutPage() {
  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 lg:w-1/2 space-y-8 p-4">
    <p className="text-xl text-center">About Us</p>
    <p className="text-left">We started Caregiving Connect after experiencing the need to balance caring for our aging parents with continuing 
          to live our busy lives. Due to various medical conditions, using standard forms of communication such as texting 
          and calling became difficult, so we resorted to using a whiteboard to write reminders or schedules for the next day. 
          We found this worked well but updating manually became cumbersome, despite living right next to our parents. We built Caregiving Connect to digitize our digital whiteboard, allowing us to communicate remotely.
          This tool keeps our family in sync and reduces the stress of constant reminders and forgetfulness.</p>
  </div>
  );
}

export default AboutPage;