import React from 'react';
import { Helmet } from 'react-helmet';
// import { StateContext } from '../stateContext';
import notification_request from '../assets/images/notification_request.PNG'
import CCSafariIOS from '../assets/images/CCSafariIOS.PNG'
import androidDownloadApp from '../assets/images/androidDownloadApp.png'
import androidDownloadAppConfirm from '../assets/images/androidDownloadAppConfirm.png'
import addToHome from '../assets/images/addToHome.PNG'
import { Link } from 'react-router-dom';

function AppOverviewPage() {
  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 space-y-8 p-4">
    <Helmet>
      <title>Caregiving Connect Mobile App</title>
      <meta name="description" content="The easiest way to interact with your loved one's displays from anywhere." />
      <meta property="og:title" content="Caregiving Connect Mobile App" />
      <meta property="og:description" content="The easiest way to interact with your loved one's displays from anywhere." />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Caregiving Connect Mobile App" />
      <meta name="twitter:description" content="The easiest way to interact with your loved one's displays from anywhere." />
    </Helmet>
    <p className="text-2xl font-bold text-center">The Caregiving Connect Mobile App</p>
    <div>
      <p className="text-center text-xl">Learn how to download our app to get notifications from care recipient displays right on your phone.</p>

    {/* <p className="text-center">Did you know you can get notifications from care recipient displays right on your phone?</p>
    <p className="text-center">Read on to learn how to download our app and enable notifications.</p> */}
    </div>
    <p className="text-left indent-8">Caregiving Connect is a modern web app that acts like a website, but also can be saved as an app to your phone's home screen. Saving the app provides a simpler interface, easier access, and notification support.</p>
    <div id="app-download" className="flex flex-col w-full px-4 py-4">
    <p className="text-center text-xl font-bold mb-4">How to save our mobile app</p>
    <p className="text-center w-full lg:w-3/4 mx-auto">Simply navigate to CaregivingConnect.com on your mobile device's browser and follow the images below to save it to your home screen.</p>
    <div className="flex flex-col lg:flex-row justify-center items-center gap-4">
        <div className="w-full lg:w-1/2">
            <p className="text-center text-xl font-bold mb-2">iPhone</p>
            <div className="flex justify-center items-center gap-4">
                <img src={CCSafariIOS} className="w-1/3 lg:w-2/5 max-w-xs h-auto" alt="Step 1 screenshot"/>
                <img src={addToHome} className="w-1/3 lg:w-2/5 max-w-xs h-auto" alt="Step 2 screenshot"/>
            </div>
        </div>
        <div className="w-full lg:w-1/2">
            <p className="text-center text-xl font-bold mb-2">Android</p>
            <div className="flex justify-center items-center gap-4">
                <img src={androidDownloadApp} className="w-1/3 lg:w-2/5 max-w-xs h-auto" alt="Step 3 screenshot"/>
                <img src={androidDownloadAppConfirm} className="w-1/3 lg:w-2/5 max-w-xs h-auto" alt="Step 4 screenshot"/>
            </div>
        </div>
    </div>
</div>
    <div id="notifications" className="flex flex-col">
      <div className="flex flex-col md:flex-row items-center justify-center">
        <p className="text-center text-xl font-bold  md:hidden">How to enable notifications in the app.</p>

        {/* <p className="text-left md:hidden">After saving, opening, and logging in to the Caregiving Connect app, you will be prompted to allow notifications.</p> */}
        <div className=" flex flex-grow md:order-last justify-center p-2 w-full">
          <img src={notification_request} className="rounded-xl w-1/2 lg:w-3/4 h-auto" alt="notification request screenshot"/>
        </div>
        <div className="flex flex-col flex-shrink w-auto">
          <p className="text-center text-xl font-bold hidden md:block">How to enable notifications in the app.</p>
          <p className="text-left indent-8">
          After saving, opening, and logging in to the Caregiving Connect app, you will be prompted to allow notifications.
          Tap allow if you would like notifications from any display. You can then turn off notifications from any displays on the dashboard.</p>
          <p className="text-center text-lg pt-4">Did you select "Don't Allow" on accident?</p>
          <p className="text-left indent-8">No problem. To allow notifications again, just remove the app by holding down the app's icon on your home screen and tapping delete. Then repeat the steps above to re-download  the app again and it will prompt you again when you open and log in.</p>
        </div>
      </div>
        
    </div>
    <div id="testing-notifications">
      <p className="text-center text-xl font-bold">Testing Notifications.</p>
      <p className="text-left indent-8">
        If you suspect notifications aren't arriving on your mobile device, you can test them from your <Link to="/account" className="link">Account</Link> page. 
        Head to your account and look for the "Send Test Notification" button on your account page. 
        Clicking this will both request notifications be allowed on the device you're using, as well as send a test notification to the last 3 devices you've enabled notifications under this account. 
        This means you can log in with your account on up to three devices and get notifications on those devices. 
        This could be your iPhone, iPad, or even a laptop. You should then receive notifications on these devices.
      </p>
    </div>
    <div id="website-vs-app">
      <p className="text-center text-xl font-bold">Website vs App</p>
      <p className="text-left indent-8">So you might be thinking, what's the difference between the website and the app? Well the answer is not much other than how the page is displayed. The website is displayed in a browser like Chrome or Safari while the app exists in it's own container on your phone like any other app. This unique container enables notifications so we suggest this method on mobile devices.</p>
    </div>
  </div>
  );
}

export default AppOverviewPage;