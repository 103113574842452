import React from 'react';
// import { StateContext } from '../stateContext';
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function FAQPage() {
  return (
  <div className="flex flex-col justify-evenly mx-auto w-full md:w-3/4 gap-2 p-4">
    <Helmet>
        <title>Caregiving Connect Frequently Asked Questions</title>
        <meta name="description" content="Get your Caregiving Connect questions answered." />
        <meta property="og:title" content="Caregiving Connect Frequently Asked Questions" />
        <meta property="og:description" content="Get your Caregiving Connect questions answered." />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Caregiving Connect Frequently Asked Questions" />
        <meta name="twitter:description" content="Get your Caregiving Connect questions answered." />
    </Helmet>
    <p className="text-3xl font-bold text-center">Frequently Asked Questions</p>
    <div className="flex flex-col items-center w-full gap-4">
      <div id="what_is_CC" className="collapse collapse-arrow border border-base-300 bg-base-200">
        <input type="radio" name="my-accordion" defaultChecked /> 
        <div className="collapse-title text-xl font-medium">
          What is Caregiving Connect?
        </div>
        <div className="collapse-content"> 
          <p>Caregiving Connect is webpage that acts as a message display tailored to getting information to care recipients through the simplest interface imaginable. It displays messages, reminders, events, that caregivers can send remotely as well as photos, weather, and more.</p>
        </div>
      </div>
      <div id="who_its_for" className="collapse collapse-arrow border border-base-310 bg-base-200">
        <input type="radio" name="my-accordion" /> 
        <div className="collapse-title text-xl font-medium">
          Who is Caregiving Connect for?
        </div>
        <div className="collapse-content"> 
          <p>Caregiving Connect is designed for all types of caregiving scenarios. Whether you are a professional healthcare provider, or a family caregiver, our platform can make both your and your patient's lives easier connecting you through a tailored, simple interface no matter the distance.</p>
        </div>
      </div>
      <div id="display_device" className="collapse collapse-arrow border border-base-310 bg-base-200">
        <input type="radio" name="my-accordion" /> 
        <div className="collapse-title text-xl font-medium">
          Do I need buy a special device from you to use as a display?
        </div>
        <div className="collapse-content"> 
          <p>No special hardware is required to use Caregiving Connect. We find most people have a capable device lying around their house. Our platform is accessible via any web-enabled device, such as a smartphone, tablet, or computer, ensuring you can stay connected and informed from anywhere. Read more about suggested devices  <Link to="/devices" className="link">here</Link>.</p>
        </div>
      </div>
      <div id="display_device" className="collapse collapse-arrow border border-base-310 bg-base-200">
        <input type="radio" name="my-accordion" /> 
        <div className="collapse-title text-xl font-medium">
          Can I use our family computer as the display?
        </div>
        <div className="collapse-content"> 
          <p>Definitely. Since the display is simply a webpage, just bookmark your unique display page and default to this when the device is not in use.</p>
        </div>
      </div>
      <div id="trial_or_refund" className="collapse collapse-arrow border border-base-310 bg-base-200">
        <input type="radio" name="my-accordion" /> 
        <div className="collapse-title text-xl font-medium">
          Do you offer a trial or refund?
        </div>
        <div className="collapse-content"> 
          <p>Of course! We recognize every situation is different and Caregiving Connect works great for some but not others. Therefore we offer a <strong>30 Day free trial</strong> with no payment method required to give you the chance to try it out and see how it works for you.</p>
        </div>
      </div>
      <div id="how_to_get_notifications" className="collapse collapse-arrow border border-base-310 bg-base-200">
        <input type="radio" name="my-accordion" /> 
        <div className="collapse-title text-xl font-medium">
          Can I get notifications when a display is interacted with?
        </div>
        <div className="collapse-content"> 
          <p>Yes you can get notifications when care recipients interact with a display such as acknowledging messages or requesting calls. Just navigate to this website on your smartphone's browser, click this button <ArrowUpOnSquareIcon className="h-6 w-6 inline-block mx-1"/> and select "add to home screen". Then just open the app, log in, and click "allow notifications" when prompted. The app will then act like any other app on your phone, saving your login info and providing a convenient mobile interface to use Caregiving Connect. We also offer email notifications for any events as well. Read more about how to download our app <Link to="/app-overview" className="link">here</Link>.</p>
        </div>
      </div>
      <div id="how_to_get_notifications" className="collapse collapse-arrow border border-base-310 bg-base-200">
        <input type="radio" name="my-accordion" /> 
        <div className="collapse-title text-xl font-medium">
          Can I put displays in multiple rooms around the house?
        </div>
        <div className="collapse-content"> 
          <p>Yes a single care recipient display can be shown on multiple devices around the house. Every time you open a display page on a new device, you are prompted to name the display. This name is used to identify which device the care recipient interacted with. If you need unique displays for multiple care recipients, you can <Link to="/pricing" className="link">upgrade</Link> your subscription to support more unique displays.</p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default FAQPage;