import React from 'react';
// import { StateContext } from '../stateContext';


function AboutPage() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4 text-center">Terms of Service</h1>
      <p className="mb-4">
        Welcome to Podtube! These Terms of Service ("Terms") govern your use of our services,
        so please read them carefully before accessing or using our platform.
      </p>
      <h2 className="text-xl font-semibold mb-2">Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing or using Podtube, you agree to be bound by these Terms and our Privacy
        Policy. If you do not agree with any part of these Terms, please do not use our services.
      </p>
      <h2 className="text-xl font-semibold mb-2">User Responsibilities</h2>
      <p className="mb-4">
        You are responsible for the content you upload, including images, text, and other media.
        Ensure that you have the necessary rights and permissions to use and process such content
        on our platform.
      </p>
      <h2 className="text-xl font-semibold mb-2">Prohibited Activities</h2>
      <p className="mb-4">
        Users are prohibited from engaging in any illegal, harmful, or abusive activities on
        Podtube. This includes but is not limited to spamming, uploading copyrighted content
        without permission, and violating the rights of others.
      </p>
      <h2 className="text-xl font-semibold mb-2">Disclaimer of Warranties</h2>
      <p className="mb-4">
        Podtube provides its services on an "as is" and "as available" basis, without any
        warranties or guarantees. We do not guarantee the accuracy, reliability, or suitability of
        the content processed on our platform.
      </p>
      <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
      <p className="mb-4">
        Podtube and its affiliates shall not be liable for any indirect, incidental,
        consequential, or punitive damages arising from your use of our services or inability to
        access our platform.
      </p>
      <h2 className="text-xl font-semibold mb-2">Changes to Terms</h2>
      <p className="mb-4">
        We reserve the right to modify or update these Terms at any time. Any changes will be
        effective upon posting on our website. Continued use of Textractify after such changes
        constitutes acceptance of the updated Terms.
      </p>
      <p className="mb-4">
        If you have any questions or concerns about these Terms, please login to access our email and reach out.
      </p>
    </div>
  );
}

export default AboutPage;