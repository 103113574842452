import React from 'react';
import { Link } from 'react-router-dom';
import { Monitor, Mic, Music, Menu, Phone, Tablet, Clock, Camera, ArrowRight, ArrowLeft, Calendar, Sun, Smile, Image, ThumbsUp, Video, MessageSquare, Smartphone, Info, Activity, Zap } from 'react-feather';
import { NewspaperIcon, SunIcon, PhotoIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import laptopDisplayPage from '../assets/images/laptopDisplayPage.png'
import displayCode from '../assets/images/displayCode.png'
import { useNavigate } from 'react-router-dom';
import IpadDisplayPage from '../assets/images/IpadDisplayPage.png'
import { Helmet } from 'react-helmet';

function DisplayOverviewPage() {
  const navigate = useNavigate()
  return (
  <div className="flex flex-col justify-center mx-auto xl:w-3/4 space-y-4 p-4">
    <Helmet>
      <title>Caregiving Connect | Display Overview</title>
      <meta name="description" content="Use our always on display on any device to send messages to your loved ones." />
      <link rel="canonical" href={window.location.href} />
      <meta property="og:title" content="Caregiving Connect | Display Overview." />
      <meta property="og:description" content="Use our always on display on any device to send messages to your loved ones." />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Caregiving Connect | Display Overview." />
      <meta name="twitter:description" content="Use our always on display on any device to send messages to your loved ones." />
    </Helmet>
    <p className="text-2xl font-bold text-center">The Caregiving Connect Display</p>
    <div>
      <p className="text-center text-xl">Discover how our always-on display simplifies caregiving, across any distance.</p>
      <div className="w-full md:w-3/4 mx-auto">
        <img src={IpadDisplayPage} className="" alt="demo display"></img>
      </div>

    {/* <p className="text-center">Did you know you can get notifications from care recipient displays right on your phone?</p>
    <p className="text-center">Read on to learn how to download our app and enable notifications.</p> */}
    </div>
    <p className="text-left indent-8 md:w-3/4 mx-auto">The Caregiving Connect display is a simple to use interface optimized for care recipients of various conditions. The goal is to extend care recipient's independence past when traditional communication channels like texting or dialing a phone become impossible.</p>
    <p className="text-center text-xl font-bold mb-2">Connecting to the display</p>
    <div id="" className="flex flex-col lg:grid-cols-2 gap-4 lg:grid lg:items-center w-full md:w-3/4 mx-auto px-4 py-4">
        <ul className="list-disc">
        <li className="text-left">Each display is assigned a unique 6-digit code when created.</li>
        <li className="text-left">Enter this code at caregivingconnect.com/display to access your personalized display.</li>
        <li className="text-left">Repeat on multiple devices throughout the house, say one in the kitchen and one in the bedroom.</li>
        {/* <li className="text-left">Name each device you load the display on around the house so you know which was interacted with.</li> */}
        </ul>
      <div className="w-full pt-2 md:w-3/4 mx-auto">
        <img src={displayCode} className="rounded-xl" alt="display code entry"></img>
      </div>
    </div>
    {/* <div id="" className="flex flex-col w-full px-4 py-4">
      <p className="text-center text-xl font-bold mb-2">Devices vs care recipient displays</p>
      <p className="text-left">We mentioned above that you can show a display on as many devices as you'd like around the house, but if you'd like to send different messages to each display, maybe you have two care recipients living at separate locations, you can upgrade to the <strong>Family</strong> plan which allows you to create new unique displays each with their own codes.</p>
    </div> */}
    <div className="flex flex-row items-center max-w-lg gap-4 mx-auto">
      <div className="flex flex-col items-center gap-2">
        <Info className="h-8 w-8"/>
        {/* <p>Tip:</p>  */}
      </div>
      <p>Caring for multiple loved-ones at different locations? Upgrade to our <a href="/pricing" target="_blank" className="link">Family</a> plan to send unique messages to each.</p>
    </div>
    <p className="text-center text-xl font-bold mb-2">Display Communication Features</p>
    <p className="text-left indent-8 w-full md:w-3/4 mx-auto">The Caregiving Connect display implements a number of methods of communication likely to work for most care recipients. We put new items front and center on the display, covering any other interesting content on the screen so encourage care recipients to view the new item.</p>
    <div id="" className="flex flex-col lg:flex-row items-start gap-4 mx-auto w-full">
      <div className="flex flex-col gap-2 max-w-lg mx-auto justify-center w-full">
        <p className="text-center text-xl">Caregivers can:</p>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <MessageSquare className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Send messages</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Schedule one-time or recurring messages.</li>
              <li>Choose from multiple acknowledgement button types to get useful answers.</li>
              <li>Set message timeouts to display only while relevant.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <Calendar className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Schedule calendar events</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Schedule one-off or recurring daily events.</li>
              <li>Select when to display prior to the event time.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row items-center justify-between text-xl">
            <div className="flex flex-shrink-none flex-row items-center gap-2">
              <Video className="w-12 h-12 inline-block" />
              <h3 className="text-xl text-left font-semibold">Drop in with video calls </h3>
            </div>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Initiate two-way calls with your loved ones any time with no action required on their part.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="divider divider-vertical lg:divider-horizontal w-3/4 mx-auto m-0"/>
      <div className="flex flex-col gap-2 max-w-lg mx-auto justify-center">
        <p className="text-center text-xl">Care recipients can:</p>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <ThumbsUp className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Acknowledge messages and events</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Each message or event is displayed with at least an "Ok" button.</li>
              <li>New messages cover entertainment content until acknowledged.</li>
              <li>Sends a notification with the acknowledgement answer to the caregiver when new items are acknowledged.</li>
              <li>View all recent acknowledgements on the caregiver's dashboard.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <Phone className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Request calls</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Large call request buttons simplify this task of dialing phone numbers.</li>
              <li>Buttons are always visible for easy access, enabling loved ones to request calls anytime.</li>
              <li>Select "now" or "tonight" to indicate the urgency of the response needed.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <Mic className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Record audio messages</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Loved ones can leave audio messages immediately by holding down the spacebar or tapping the microphone icon.</li>
              <li>These recordings send notifications instantly and can be played directly from the caregiver dashboard.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <Zap className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Monitor battery level</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Get notified when the display is unplugged or running low on battery power.</li>
              <li>Ensure the display device is ready for communication at all times by keeping tabs on its charging and battery states.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row items-center justify-between text-xl">
            <div className="flex flex-shrink-none flex-row items-center gap-2">
              <Activity className="w-12 h-12 inline-block" />
              <h3 className="text-xl text-left font-semibold">Trigger motion events </h3>
            </div>
            <div className="bg-primary rounded-full text-white text-center text-sm px-2 py-1 xl:whitespace-nowrap">Upcoming Feature</div>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Get alerts when the device's webcam detects motion.</li>
              <li>On-device motion detection means your webcam data stays local, sharing only trigger event timestamps with our servers.</li>
              <li>Get first-motion notifications to know when your loved one is up for the day and see other motion events throughout the day.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="" className="flex flex-col w-full px-4 py-4">
      <p className="text-center text-xl font-bold mb-2">Display Entertainment</p>
      <p className="text-left indent-8 w-full md:w-3/4 mx-auto">We recognize that motivation to interact with and check the display often goes a long way to promoting the care recipient's continued use of the display. That's why we have come up with a growing list of interesting items that can be added to the display to offer value to your loved ones on top of communication. Select from one, many, or all of them to be rotated through at a set interval to keep things fresh.</p>
      <div className="flex flex-col gap-2 justify-center max-w-lg mx-auto pt-4">
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <Image className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Photos</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Transform any screen into a dynamic digital photo frame.</li>
              <li>Choose from curated nature photo collections or upload personal photos.</li>
              <li>Future updates will include integration with Google Photos Albums for seamless photo management and display.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <Sun className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Weather Conditions</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Seamlessly add valuable weather information with the rest of the display info.</li>
              <li>Includes current weather conditions and future forecasts.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <Smile className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Daily Jokes</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Add a little humor to their daily routine with a fresh joke displayed each day.</li>
              <li>Designed to provide light-hearted moments that can uplift spirits and improve engagement with the display.</li>
            </ul>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row items-center justify-between text-xl">
            <div className="flex flex-row items-center gap-2">
              <NewspaperIcon className="w-12 h-12 inline-block" />
              <h3 className="text-xl text-left font-semibold">News</h3>
            </div>
            <div className="bg-primary rounded-full text-white text-sm px-2 py-1">Upcoming Feature</div>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Stay up to date with the latest news headlines from a variety of categories.</li>
              <li>Updates throughout the day to keep the information timely and relevant.</li>
              <li>Select from multiple news categories for a personalized news feed.</li>
            </ul>
          </div>
        </div><div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row items-center justify-between text-xl">
            <div className="flex flex-row items-center gap-2">
              <Music className="w-12 h-12 inline-block" />
              <h3 className="text-xl text-left font-semibold">Music</h3>
            </div>
            <div className="bg-primary rounded-full text-white text-sm px-2 py-1">Upcoming Feature</div>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <ul className="list-disc list-outside px-4">
              <li>Studies have shown the positive effect music can have on the elderly and their health.</li>
              <li>Connect with popular streaming services and select from your playlists.</li>
              <li>Control the music remotely or automate to play at certain times of the day.</li>
            </ul>
          </div>
        </div>
      </div>
    <button className="btn btn-primary mt-2 w-auto mx-auto" onClick={()=>navigate('/dashboard-overview')}>Learn about the dashboard</button>

    </div>
  </div>
  );
}

export default DisplayOverviewPage;