import React, { useState, useEffect, useContext } from 'react';
import apiClient from '../utils/apiClient';
import { StateContext } from '../stateContext';
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary

const GroupInvitesModal = ({ invites, onClose }) => {
      const { profile, setProfile, fetchUserProfile } = useUser();

  const [isLoading, setIsLoading] = useState(false)
  const [pendingInvites, setPendingInvites] = useState(invites)

    const handleGroupInvite = async (accept, groupID) => {
    try {
        setIsLoading(true);
        const response = await apiClient.post('/acceptGroupInvite', {
            accept,
            groupID,
            onboarding: true
        });
        // Correctly apply the filter method to update theseInvites
        const updatedInvites = pendingInvites.filter(invite => invite.groupID !== groupID);
        if (updatedInvites.length === 0) {
          fetchUserProfile()
          onClose()
        }
        setPendingInvites(updatedInvites);
        return response.status === 200;
    } catch (error) {
        console.error('Error handling invite:', error);
        return false;
    } finally {
        setIsLoading(false);
    }
  };


  return (
    <div className={`modal modal-open`}>
      <div className="modal-box relative">
        <label htmlFor="my-modal" className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onClose}>✕</label>
        <h3 className="text-lg font-bold">{`Pending Group Invitations`}</h3>
        <div className="flex flex-col items-center gap-4"> {/* Wrap the map results in a div */}
          {pendingInvites?.map((invite, index) => (
            <div key={index} className="flex flex-col text-center border p-4 rounded-lg">
              <p className="text-lg">
                You've been invited {invite?.groupName ? `to "${invite?.groupName}"` : ""} {invite?.inviterName ? ` by ${invite.inviterName}` : ""}.
              </p>
              <p className="">
                Click accept to join the group or decline to continue with your own group.
              </p>
              <div className="flex flex-row items-center justify-center gap-4">
              <button className="btn mr-2" onClick={()=>handleGroupInvite(false, invite.groupID)}>
                Decline
              </button>
              <button className="btn" onClick={()=>handleGroupInvite(true, invite.groupID)}>
                Accept
              </button>
              </div>
            </div>
          ))}
          {/* <div className={`modal-action justify-right`}>
            <div className="flex flex-row items-center">
              <label htmlFor="my-modal" className="btn" onClick={onClose}>Skip</label>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
};

export default GroupInvitesModal;