// src/firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth"; // Import setPersistence and persistence types
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyD9XVRs3vE4Q4GMYIHNKAuYtndedguQmUQ",
  authDomain: "carecast-4037a.firebaseapp.com",
  databaseURL: "https://carecast-4037a-default-rtdb.firebaseio.com",
  projectId: "carecast-4037a",
  storageBucket: "carecast-4037a.appspot.com",
  messagingSenderId: "453955297129",
  appId: "1:453955297129:web:158b82520a6efbe42827f8",
  measurementId: "G-K1YFXSF99L"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence); // Set to browser local storage persistence

const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const db = getDatabase(app)


// Optional: Listen for messages when the app is in the foreground
// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   // Process your message as required
// });
export { auth, provider, app, messaging, onMessage, db, analytics };