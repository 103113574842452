import React, { useEffect, useState, useContext } from 'react';
import { useUser } from '../utils/UserProvider'; // Adjust the import path as necessary
import { User, Info } from 'react-feather';
import apiClient from "../utils/apiClient";
// import PricingPage from '../components/PricingRow';
import { useNavigate, Link } from 'react-router-dom';
import { StateContext } from '../stateContext';
// import { analytics } from '../utils/firebase-config';
import GroupInvitesModal from '../components/GroupInvitesModal';
import FeedbackModal from '../components/FeedbackModal'
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AccountPage = () => {
    // const { settings, setSettings, setToastData, toastData, selectedFiles, setSelectedFiles, uploads, setUploads, setFileStatus, fileStatus, initialSettings, } = useContext(StateContext);
  const { user, signOut, profile, getFCMToken, fetchUserProfile, resendVerificationEmail } = useUser();
  // const [timezone, setTimezone] = useState(profile?.timeZone || null);
  const [name, setName] = useState(profile?.name || '');
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [showNotificationPageInfo, setShowNotificationPageInfo] = useState(false);
  const [showGroupInvitesModal, setShowGroupInvitesModal] = useState(false);
  const [leaving, setLeaving] = useState(false);
  const {setToastData} = useContext(StateContext);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (profile?.groupInvites) setShowGroupInvitesModal(true)
  }, []);
  
// const handleTimezoneChange = (e) => {
//     setTimezone(e.target.value);
//   };

async function sendTestMessage() {
  try {
    // Check if Notification API is available
    let permission
    if (typeof Notification === "undefined") {
      setToastData({text:"Notifications unavailable in this environment.", show:true});
      setShowNotificationPageInfo(true)
      // or send to notification info page
    } else {
      // Check notification permission status
      let permission = Notification.permission;
      if (permission !== 'granted') {
        // Ask the user for permission
        try {
          permission = await Notification.requestPermission();
        } catch (error) {
          setToastData({text:error.message, show:true});
          setShowNotificationPageInfo(true)
        }
      }
    }

    let fcmToken
    if (permission === 'granted') {
      // User has allowed notifications
      fcmToken = await getFCMToken();
      if (!fcmToken) {
        console.error('No FCM Token available');
        setToastData({text:"No notification token available.", show:true});
        return;
      }
    } else {
      // Permission was denied or dismissed
      console.log('Notification permissions denied.');
      setToastData({text:"Notification permission denied.", show:true});
    }

    // send notification request to trigger other devices either way
    const response = await apiClient.post('/send-test-message', {
      fcmToken
    });
    setToastData({text:"Test message sent successfully.", show:true});

  } catch (error) {
    console.error('Error sending test message:', error);
    setToastData({text:"Unable to send notifications.", show:true});
  }
}

const handleSubscriptionUpdate = async (e) => {
  try {
    setSubscriptionLoading(true)
    const response = await apiClient.post('/create-checkout-session');
    // window.location.href = response.data.url;
    window.open(response.data.url, '_blank');
  } catch (error) {
    console.error('Error updating subscription:', error);
    setToastData({text:"Error updating subscription. Reach out to support@caregivingconnect.com to resolve this.", show:true})
  } finally{
    setSubscriptionLoading(false)
  }
}

function SubscriptionDetails({ profile }) {
  if (!profile) return null; // Return null if no profile data is available

  const { active, name, clientLimit, caregiverLimit } = profile.subscription;

  return (
    <div className="text-center flex flex-col gap-2">
      {/* <h3 className="text-lg font-semibold mb-2">Subscription Details</h3> */}
      <p>
        {active 
          ? <span>Your subscription tier: <strong>{name}</strong></span>
          : <div>
              <p>You are not subscribed. </p>
              <p>Any care recipient displays still in your account will no longer receive updates.</p>
            </div>}
      </p>
      {!profile.whitelisted && profile.subscription.status === "trialing" && <p>Your trial will end {profile?.trial_days_remaining ? `in ${profile?.trial_days_remaining} day${profile?.trial_days_remaining === 1 ? "" : "s"}.` : "soon."}</p>}
      {active && clientLimit && caregiverLimit && (
          <div>
            <p>This tier allows you to create <strong>{clientLimit}</strong> display{clientLimit === 1 ? "" : "s"} and add <strong>{caregiverLimit}</strong> caregiver{caregiverLimit === 1 ? "" : "s"} to manage those displays.</p>
          </div>
        )}
      <div className="flex flex-col gap-2">
      {!profile.whitelisted && (profile.subscription.status === "active" ? 
        <button className="btn mx-auto" onClick={handleSubscriptionUpdate}>{subscriptionLoading ? <div className="loading loading-spinner loading-sm" /> : "Update Subscription"}</button>
      :
        <button className="btn btn-success mx-auto" onClick={()=>navigate('/pricing')}>{subscriptionLoading ? <div className="loading loading-spinner loading-sm" /> : "Subscribe"}</button> 
      )}
      {profile.whitelisted && <p>You have been given free access. Reach out to support@caregivingconnect.com if you'd like to upgrade your subscription.</p>}
      {profile.subscription.status !== "trial_over" && <Link to="/pricing" className="link">See all plans</Link>}
      
      </div>
    </div>
  );
}

// Example usage in an onClick event handler:
// <button onClick={() => sendTestMessage('user-id', 'Sample Podcast Title')}>Send Test Message</button>

  const handleUpdateProfile = async () => {
    try {
      const response = await apiClient.post(`/setProfile`, {
        // timeZone: timezone,
        name
      });
      // Handle response as needed
      setToastData({ text: "Account updated successfully", show: true });

    } catch (error) {
      console.error('Error updating account:', error);
      setToastData({ text: "Account update failed", show: true });

    }
  };

  const handleLeaveGroup = async () => {
    if (!window.confirm(`Are you sure you want to leave ${profile.activeGroup.groupName}? The owner will have to add you again to access it again in the future.`)) {
        return;
    };
    try {
      setLeaving(true)
      const response = await apiClient.post(`/leaveGroup`, {
        groupID: profile.activeGroup.groupID
      });
      await fetchUserProfile()
      setToastData({ text: "Left group successfully", show: true });
    } catch (error) {
      console.error('Failed to leave group:', error);
      setToastData({ text: "Failed to leave group", show: true });

    } finally{
      setLeaving(false)
    }
  };

  const handleGroupChange = async (e) => {
    try {
      setLeaving(true)
      const response = await apiClient.post(`/setProfile`, {
        latestGroupID: e.target.value
      });
      await fetchUserProfile()
      setToastData({ text: "Group changed successfully", show: true });
    } catch (error) {
      console.error('Failed to change group:', error);
      setToastData({ text: "Failed to change group", show: true });
    } finally{
      setLeaving(false)
    }
  };



  const handleSignOut = async () => {
    signOut()
    navigate("/")
  }

  return (
    <div className="w-full md:w-4/5 mx-auto ">
      {showGroupInvitesModal && (
        <GroupInvitesModal
        invites={profile.groupInvites}
        onClose={()=> setShowGroupInvitesModal(false)}
        />
      )}
      {showFeedbackForm && 
      <FeedbackModal
        type={'accountFeedback'}
        onClose={()=>setShowFeedbackForm(false)}
      />
    }
      <div className="flex flex-col items-center justify-center m-4 md:p-4 rounded-lg md:shadow-lg">
        <p className="text-xl font-bold text-center">My Account</p> 
        {user && (
          <div className="flex flex-col p-4 gap-4 items-center">
            {user?.photoURL && <img alt="user" src={user.photoURL} className="rounded-full" />}

            <div className="flex flex-col md:flex-row items-center justify-center p-2">
              <p className="p-2">Email:</p>
              <div className="">
                <input disabled type="text" id="account_email" value={user.email} className="input input-bordered max-w-xs" />
              </div>
            </div>
            {!user.emailVerified && <div className="flex flex-col items-center justify-center">
              <p className="text-center">Please check your email or click below to send your verification email again.</p>
              <p className="text-center">You will not be able to access any other groups until you verify your email.</p>
              <button className="btn btn-normal" onClick={resendVerificationEmail}>Resend Verification Email</button>
              </div>}
            <div className="flex flex-col">
            <div className="flex flex-col md:flex-row items-center justify-center p-2">
              <p className="p-2">Name:</p>
              <div className="">
                <input type="text" placeholder="Name" id="account_name" value={name} className="input input-bordered max-w-xs text-center" onChange={(e)=>setName(e.target.value)} onBlur={handleUpdateProfile}/>
              </div>
            </div>
            <p className="text-sm italic text-center">We'll use your name when displaying and announcing new items such as "{name ? name : "John"} sent a new message."</p>
            <button className="btn btn-sm mx-auto" onClick={()=>setShowFeedbackForm(true)}>Share Feedback</button>
            </div>
            
            {profile.activeGroup && 
            // make sure not owner
            <div className="flex flex-col justify-middle items-center w-full">
              <div className="divider m-0">Groups</div>
              <div className="flex flex-col justify-center items-center p-2 gap-2 w-full">
                <p className="text-center">Current group:</p>
                {profile?.groups?.length > 1 ? 
                <select className="select select-bordered" disabled={!user.emailVerified} value={profile?.activeGroup.groupID} onChange={handleGroupChange}>
                  {profile?.groups.map((group) => (
                    <option key={group.groupID} value={group.groupID}>{group.groupName}</option>
                  ))}
                </select> : 
                <p className="text-center font-semibold">{profile.activeGroup.groupName}</p>
                }
              {profile?.groups.length > 1 && 
              <button  className="btn btn-circle">
                <div className="tooltip tooltip-bottom" data-tip={`The current group determines the group you interact with on the dashboard and does not affect which notifications you receive.`}>
                    <Info className="h-6 w-6"/>
                </div>
                </button>
              }
              </div>
              {profile?.groups?.length > 1 && !user.emailVerified && <p className="text-error text-center">You must verify your email before switching to a group you were invited to.</p>}
              <p className="text-center font-semibold">You are {profile.activeGroup.role === "owner" ? "the" : "a"} {profile.activeGroup.role} of "{profile.activeGroup.groupName}".</p>
              {profile?.activeGroup.role !== "owner" && <button className="btn btn-sm w-48 m-4 btn-error" onClick={handleLeaveGroup}>{leaving ? <div className="loading loading-spinner loading-sm" /> : "Leave Group"}</button>}
              {profile?.activeGroup.role === "owner" && <p className="text-sm italic text-center">This group's display and caregiver limits are determined by your subscription.</p>}
              {profile?.activeGroup.role === "owner" && <p className="text-sm italic text-center">Reach out to us at support@caregivingconnect.com if you need to transfer group ownership to another user.</p>}
            
            </div>}
            <div className="divider m-0">Notifications</div>
            <div className="flex flex-col items-center">
            {/* <div className="flex flex-col md:flex-row items-center justify-center p-2">
              <p className="p-2">Get app notifications:</p>
              <div className="">
                <input type="checkbox" className="toggle" checked={getNotifications} id="getNotifications" onChange={handleToggle}/>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center p-2">
              <p className="p-2">Get email notifications:</p>
              <div className="">
                <input type="checkbox" className="toggle" checked={getEmailNotifications} id="getEmailNotifications" onChange={handleToggle}/>
              </div>
            </div> */}
            <p className="text-center">You can modify your notification settings for each client individually on the <Link to="/dashboard" className="link">Dashboard</Link>.</p>
            <p  className="text-center">Click the button below to send a test notification to all of your devices with notifications enabled.</p>
            {/* <p className="text-center">Log in again to any devices that did not receive a notification.</p> */}
            <button className="btn btn-sm" onClick={sendTestMessage}>Send Test Notification</button>
            {/* <button className="btn btn-normal m-2" onClick={()=>window.location.reload()}>Refresh App</button> */}
            {/* <p>Having trouble getting notifications? Read about how they work here:</p> */}
            <p className="link" onClick={() => window.open('/app-overview', '_blank')}>Learn about how notifications work</p>
            </div>
            <div className="divider m-0">Subscription</div>

            
            <div className="flex flex-col" >
            <SubscriptionDetails profile={profile} />
            </div>
            {/* {profile?.credits >= 0 && (
              <div className="stats shadow">
                <div className="stat">
                  <div className="stat-title">Credit Balance:</div>
                  <div className="stat-value">{profile?.credits}</div>
                  <div className="stat-desc">credits</div>
                </div>
              </div>
            )} */}
            {/* <PricingPage tier={profile?.subscription.tier} /> */}
            <button className="btn btn-sm btn-error" onClick={handleSignOut}>Log out</button>
            <p className="text-sm">Reach out any time for help, feedback, or anything else at support@caregivingconnect.com.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountPage;
