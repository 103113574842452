import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import alexa_together_logo from '../assets/images/alexa_together_logo.png'
import displaydemo from '../assets/images/displaydemo.png'
// import captions from '../assets/images/captions.png'

// Mock data for blog posts
const posts = [
  {
    id: '/blog/goodbye-alexa-together',
    title: 'An alternative to Alexa Together?',
    subtitle: "As Amazon ends support for Alexa Together, Caregiving Connect comes online as a perfect replacement.",
    date: 'June 13, 2024',
    photo: alexa_together_logo,
    },
      {
        id: '/blog/dementia-clocks',
        title: 'Connected Messaging Through Modern Dementia Clocks',
        subtitle: "Elevate your dementia clock with Caregiving Connect's additional communication features. ",
        date: 'June 13, 2024',
        photo: displaydemo,
    
      },
  // {
  //   id: '/app-overview',
  //   title: 'How our app works',
  //   subtitle: 'Our mobile app makes quickly checking in on yor loved ones a breeze, and downloading it is easy.',
  //   date: 'June 12, 2024',
  //   // photo: video_input,
  // },
  // {
  //   id: '/display-overview',
  //   title: 'Display content options',
  //   subtitle: "Learn about the types of interesting content you can add to displays.",
  //   date: 'June 5, 2024',
  //   // photo: video_input,

  // },
  ];

function BlogPage() {
    const navigate = useNavigate()
  return (
    <div className="container mx-auto p-5">
    <Helmet>
        <title>Caregiving Connect Blog</title>
        <meta name="description" content="Helpful resources to make caregiving easier." />
        <meta property="og:title" content="Caregiving Connect Blog" />
        <meta property="og:description" content="Helpful resources to make caregiving easier." />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Caregiving Connect Blog" />
        <meta name="twitter:description" content="Helpful resources to make caregiving easier." />
    </Helmet>

      <h1 className="text-3xl font-bold text-center mb-8 mt-2">Caregiving Connect Blog Posts</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {posts.map(post => (
          <div key={post.id} className="card bg-base-100 shadow-xl h-96">
            {post.photo && <figure className="pointer-events-auto hover:cursor-pointer" onClick={() => navigate(`${post.id}`)}><img src={post.photo} className="" alt={post.title} /></figure>}
            <div className="card-body">
              <h2 className="card-title">{post.title}</h2>
              <p>{post.subtitle}</p>
              <p className="text-sm text-gray-500">{post.date}</p>
              <div className="card-actions justify-end">
                <Link to={`${post.id}`} className="btn btn-primary">View article</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogPage;
