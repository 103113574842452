import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signInWithPopup, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, createUserWithEmailAndPassword, GoogleAuthProvider, signOut as firebaseSignOut } from 'firebase/auth';
import { messaging, auth } from './firebase-config'; // Adjust the path as necessary
import { getToken } from "firebase/messaging";
import apiClient from './apiClient';
import { ref, onValue, off, get } from "firebase/database";
import { db } from '../utils/firebase-config';
import local_storage from './local_storage';

const UserContext = createContext();

const EXPIRATION_TIME = 3600 * 1000; // 1 hour in milliseconds

const setWithExpiry = (key, value) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + 3600 * 2400 * 7, // 1 week
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [realtimeSetup, setRealtimeSetup] = useState(false);
  const [loading, setLoading] = useState(true);  // Added loading state

  const fetchUserProfile = async (firebaseUser) => {
    if (!firebaseUser) firebaseUser = user;
    try {
      // const token = await firebaseUser.getIdToken(true);
      const response = await apiClient.get(`/getUserData`);
      updateUserProfile()

      // if (!response.data.timeZone) {
      //   setProfile({...response.data, timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone})
      // } else 
      if (response.data.serverVersion){
        const localVersion = local_storage.getItem('appVersion');
        const serverVersion = response.data.serverVersion
        if (!localVersion || serverVersion !== localVersion) {
          local_storage.setItem('appVersion', serverVersion);
          console.log("new app version in userprovider")
          window.location.reload(true);  // Force reload to fetch new files
        }
      }
      if (JSON.stringify(response.data) !== JSON.stringify(profile)) {
        console.log(response.data)
        console.log(profile)
        console.log("new profile data")
        setProfile(response.data);
      }
    } catch (error) {
      // console.error("Error fetching user profile: ", error);
    }
  };

  const handleFirebaseForgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      // You can handle success message here if needed
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      throw error;  // Throw error to handle it in UI components
    }
  };

const getFCMToken = async () => {
    try {
        // Request permission to receive notifications
        const permission = await Notification.requestPermission();
        let fcmToken
        if (permission !== "granted") {
            console.log("Notification permission not granted");
        } else fcmToken = await getToken(messaging);
        
        return fcmToken 
    } catch (error) {
        console.error('Error updating user profile and FCM token:', error);
        return null;
    }
};


const updateUserProfile = async () => {
  try {
    // Request permission to receive notifications
    // const permission = await Notification.requestPermission();
    // Get the FCM token
    const fcmToken = await getFCMToken()
    if (fcmToken) {

      // Get the user's timezone
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Send FCM token and timezone to the backend
      const response = await apiClient.post(`/setProfile`, {
        fcmToken,
        timeZone,
      });

      // Handle the response as needed
    } else {
      console.log('Permission for notifications was not granted');
    }
  } catch (error) {
    console.error('Error updating user profile and FCM token:', error);
  }
};

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        if (!realtimeSetup) setUpRealtime(firebaseUser.uid)
        await fetchUserProfile(firebaseUser);
        setUser(firebaseUser);

        // if (profile?.firstLogin) 
      } else {
        setUser(null);
        console.log("setting profile to null")
        setProfile(null);
      }
      setLoading(false);  // Set loading to false after user check
    });

    return () => unsubscribe();
  }, [auth]);

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUser(null);
      localStorage.removeItem('userToken');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setWithExpiry("userToken", true)
      local_storage.setItem("hasLoggedIn", true)
    } catch (error) {
      console.error("Error signing in: ", error);
    }
  };

const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    setWithExpiry("userToken", true);
    setUser(userCredential.user);
    // setUpRealtime(userCredential.user.uid)
    local_storage.setItem("hasLoggedIn", true)
    // Check if the email is already verified
    if (!userCredential.user.emailVerified) {
        // Send email verification only if the email hasn't been verified yet
        await sendEmailVerification(userCredential.user);
    }    console.log("Verification email sent.");

    // Optionally, notify user about the verification email via UI
  } catch (error) {
    console.error("Error signing up: ", error);
    throw error;  // Propagate error to be handled in UI components
  }
};

const setUpRealtime = async (userID) => {
    // console.log("setting up real time")
    try {
      // Initialize Firebase app with the provided authentication token
      // Set up Realtime Database listener for the always-on display data
      const dbRef = ref(db, `users/${userID}`);
      const snapshot = await get(dbRef);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        // console.log('Received new data in user:', data);
        if (data?.action === 'account') {
          // console.log("got an account action in user")
          fetchUserProfile()
        }
      });
      // Clean up listeners or perform other cleanup actions as needed
      return () => {
          off(dbRef); // Stop listening to changes when the component unmounts
      };
      setRealtimeSetup(true)
    } catch (error) {
      console.error('Error setting up Realtime Database:', error.message);
      throw error; // Propagate the error if setup fails
    }
  };

/**
 * Resends the verification email to the current logged-in user.
 */
const resendVerificationEmail = async () => {
  try {
    if (user) {
      await sendEmailVerification(user);
      console.log("Verification email sent again.");
      // Optionally, notify the user that the email has been sent
    } else {
      // console.log("No user is currently logged in.");
      // Handle case where there is no user logged in
    }
  } catch (error) {
    console.error("Error resending verification email: ", error);
    // Handle errors here, such as showing a user-friendly error message
  }
};

const emailSignIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    setWithExpiry("userToken", true)
    setUser(userCredential.user);
    local_storage.setItem("hasLoggedIn", true)
    // setUpRealtime(userCredential.user.uid)
  } catch (error) {
    console.error("Error signing in with email: ", error);
    throw error;
  }
};

  return (
    <UserContext.Provider value={{ user, profile, signIn, signOut, signUp, emailSignIn, loading, setProfile, fetchUserProfile, handleFirebaseForgotPassword, getFCMToken, resendVerificationEmail}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
