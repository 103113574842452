import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './stateContext';
import { UserProvider }  from './utils/UserProvider'
import './utils/firebase-config'; // Add this line to import Firebase initialization

// Register the service worker
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
//     console.log('Service Worker registration successful with scope: ', registration.scope);
//   }, function(err) {
//     console.log('Service Worker registration failed: ', err);
//   });
// }


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <StateProvider>
      <UserProvider>
        <Router>
          <App />
        </Router>
      </UserProvider>
    </StateProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
