import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Users, Share, UserPlus, Heart, MessageSquare, Zap, Calendar, Bell, Send, Monitor, Globe, MousePointer, Share2, Sliders, AlertTriangle } from 'react-feather';
import { NewspaperIcon, SunIcon, PhotoIcon, CheckCircleIcon, RectangleGroupIcon, BellAlertIcon } from '@heroicons/react/24/outline';
import CCDashboard from '../assets/images/CCDashboard.png'
import ProImg1 from '../assets/images/ProImg1.jpg'
import proDisplay from '../assets/images/proDisplay.png'
import proDashboard from '../assets/images/proDashboard.png'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FeedbackReachOutModal from '../components/FeedbackModal';


function ProfessionalsPage() {
  const navigate = useNavigate()
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 p-4">
    {showFeedbackForm && 
      <FeedbackReachOutModal
        type={'professionalReachOut'}
        onClose={()=>setShowFeedbackForm(false)}
      />
    }
    <Helmet>
      <title>Caregiving Connect | Professionals</title>
      <meta name="description" content="Streamline communication with your patients through our simple to use interface." />
      <link rel="canonical" href={window.location.href} />
      <meta property="og:title" content="Caregiving Connect | Professionals." />
      <meta property="og:description" content="Streamline communication with your patients through our simple to use interface." />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Caregiving Connect | Professionals." />
      <meta name="twitter:description" content="Streamline communication with your patients through our simple to use interface." />
    </Helmet>
    <div className="flex flex-col md:flex-row gap-4">
      <div className="flex flex-col justify-center mx-auto gap-2">
        <p className="text-3xl font-bold text-center">Streamline Your Professional Caregiving with Caregiving Connect</p>
        <p className="text-center text-xl">Discover how Caregiving Connect makes communication between your team and patients easier than ever.</p>
      </div>
      <div className="w-full sm:w-2/3 md:w-full mx-auto">
        <img src={ProImg1} className="rounded-xl" alt="demo display"></img>
      </div>
    </div>
    {/* <p className="text-lg text-center py-8">In a professional caregiving environment, efficiency and communication are paramount. Caregiving Connect is designed to bridge the gap between caregivers and patients through innovative technology that enhances care for individuals across any care setting—be it residential homes or larger care facilities.</p> */}
    <p className="text-xl text-center pt-8 sm:pt-8 sm:p-4">What is Caregiving Connect for professionals?</p>
    <div className="flex flex-col md:flex-row items-center justify-center mx-auto gap-4">
      <div className="w-full sm:w-2/3 md:w-1/2 mx-auto py-2 md:py-0">
        <img src={proDisplay} className="rounded-xl" alt="demo display"></img>
      </div>
      <div className="flex flex-col justify-center gap-4">
          {/* Feature 1 */}
          <div className="flex flex-row items-start gap-4">
            <div className="w-12 h-12">
              <Globe className="w-12 h-12" />
            </div>            <div className="text-left max-w-xs rounded-lg">
              <h3 className="text-xl text-left font-semibold">Centrally Managed Displays</h3>
              <p className="text-md text-left">Effortlessly communicate with a large number of patients through their individual always-on message displays.</p>
            </div>
          </div>
          {/* Feature 2 */}
          <div className="flex flex-row items-start gap-4">
            <div className="w-12 h-12">
              <MessageSquare className="w-12 h-12" />
            </div>
            <div className="text-left max-w-xs rounded-lg">
              <h3 className="text-xl text-left font-semibold">Real-Time Communication</h3>
              <p className="text-md text-left">Send and receive messages and requests directly from patients' displays.</p>
            </div>
          </div>
          {/* Feature 3 */}
          <div className="flex flex-row items-start gap-4">
            <div className="w-12 h-12">
              <Users className="w-12 h-12" />
            </div>            <div className="text-left max-w-xs rounded-lg">
              <h3 className="text-xl text-left font-semibold">Team Collaboration</h3>
              <p className="text-md text-left">
                Assign team members access to some or all patient displays for seamless collaboration and monitoring. 
              </p>
            </div>
          </div>
        </div>
    </div>
    <button onClick={() => window.open('/display/demo', '_blank')} className="btn btn-primary text-lg text-white mx-auto mt-4">Try a Demo</button>

    <div className="flex flex-col justify-center mx-auto">
    <p className="text-2xl text-center pt-8">Optimize your care through improved communication with your patients.</p>
      <div className="flex flex-col md:flex-row justify-center">
          {/* Feature 1 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
              <Monitor className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Use almost any device</h3>
            <p className="text-md text-left">Utilize patients' existing computers, laptops, or tablets or purchase your own <a href="https://amzn.to/3VIMhR8" target="_blank" className="link">basic tablets</a> starting at $40.</p>
          </div>
          {/* Feature 2 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <RectangleGroupIcon className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">A simplified interface anyone can use</h3>
            <p className="text-md text-left">No scrolling, menus, or tabs means almost anyone can send and receive information online.</p>
          </div>
          {/* Feature 3 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <PhotoIcon className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Enhance Patient Engagement</h3>
            <p className="text-md text-left">
              Keep patients engaged and informed by seamlessly integrating content such as beautiful photos and daily weather updates. 
            </p>
          </div>
        </div>
    </div>
    <div className="flex flex-col justify-center mx-auto">
    <div className="w-full md:w-3/4 mx-auto">
      <img src={proDashboard} className="rounded-xl" alt="demo display"></img>
    </div>
    <p className="text-2xl text-center pt-8">Manage caregivers and patients all in one place.</p>
      <div className="flex flex-col md:flex-row justify-center">
          {/* Feature 1 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Sliders className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Unified caregiver dashboard</h3>
            <p className="text-md text-left">Monitor all patient interactions and caregiver activities through a single dashboard to improve operational efficiency and patient oversight.</p>
          </div>
          {/* Feature 2 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <BellAlertIcon className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Patient driven requests</h3>
            <p className="text-md text-left">Reduce unnecessary check-ins with direct patient-initiated requests, allowing caregivers to respond more effectively to actual needs.</p>
          </div>
          {/* Feature 3 */}
          <div className="text-left p-8 max-w-xs rounded-lg">
            <Share2 className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Share with family</h3>
            <p className="text-md text-left">
              Extend limited display access to patient family members to keep the patient feeling included with family photos and messages. 
            </p>
          </div>
        </div>
    </div>
    <div className="flex flex-col md:flex-row md:gap-4 items-center justify-center">
      <button className="btn btn-primary w-auto" onClick={()=>navigate('/display-overview')}>Learn more about display features</button>
      <p>or</p>
      <button className="btn btn-secondary text-black w-auto" onClick={()=>setShowFeedbackForm(true)}>Reach out to discuss</button>
    </div>
  </div>
  );
}

export default ProfessionalsPage;