import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Share, UserPlus, Heart, MessageSquare, Zap, Calendar, Bell, Send, Smartphone, Tablet, Monitor, Clock } from 'react-feather';
import { NewspaperIcon, SunIcon, PhotoIcon, CheckCircleIcon, BellAlertIcon, ArrowPathIcon, ClockIcon } from '@heroicons/react/24/outline';
import IpadDisplayPage from '../assets/images/IpadDisplayPage.png'
import laptopDisplayPage from '../assets/images/laptopDisplayPage.png'
import familyDesktop from '../assets/images/familyDesktop.jpeg'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function DeviceOverviewPage() {
  const navigate = useNavigate()
  return (
  <div className="flex flex-col justify-center mx-auto md:w-3/4 p-4">
    <Helmet>
      <title>Suggested Caregiving Connect display devices</title>
      <meta name="description" content="Learn what devices work well as the Caregiving Connect always on display." />
      <meta property="og:title" content="Our favorite devices for the Caregiving Connect always on display" />
      <meta property="og:description" content="Learn what devices work well as the Caregiving Connect always on display." />
      <meta property="og:url" content={window.location.href} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Our favorite devices for the Caregiving Connect always on display" />
      <meta name="twitter:description" content="Learn what devices work well as the Caregiving Connect always on display." />
    </Helmet>
    <p className="text-3xl font-bold text-center">Suggested Display Devices</p>
    <div className="flex flex-col justify-center mx-auto">
      <p className="text-center text-xl">Learn about what devices we've found make a great always-on display.</p>
      <div className="flex flex-row items-center justify-center w-full h-auto gap-4">
        <img src={IpadDisplayPage} alt="iPad display page example" className="w-1/3"/>
        <img src={laptopDisplayPage} alt="laptop display page example" className="w-1/3"/>
      </div>
      {/* <div className="flex flex-row items-center justify-center pt-4">
        <div className="mockup-window border bg-base-300 mx-auto">
          <img src={CCDashboard} className="" alt="demo display"></img>
        </div>
        <div className="divider divider-horizontal hidden lg:flex h-3/4 my-auto"></div>
        <img src={clientMessages} className="hidden lg:flex h-96" alt="mobile dashboard"/>
      </div> */}
    </div>
    <p className="text-lg text-center py-8">With Caregiving Connect, you don't have to purchase a specific, expensive device. You can run the display on a wide range of devices you probably already own including tablets, laptops, desktops, and even smart TVs. The main requirement is an internet connection to sync the display with the dashboard.</p>
    <div className="flex flex-col md:flex-row items-center justify-center rounded-xl gap-4  min-h-96">
          <div className="flex flex-col items-center justify-center md:w-1/2">
           <p className="text-3xl  text-center">The best device is the one you already have.</p>
           <p className="text-lg lg:text-xl w-full lg:w-3/4 text-center">Consider displaying Caregiving Connect on a device you already have when not in use.</p>
          </div>
          <div className="flex flex-col justify-center items-center p-2 gap-4 w-full md:w-3/4 mx-auto lg:w-1/2">
              <img src={familyDesktop} className=" rounded-xl" alt="notification request screenshot"/>
              {/* <img src={notification2} className=" rounded-xl" alt="notification request screenshot"/> */}
          </div>
        </div>
    
    
    <div className="flex flex-col justify-center mx-auto pt-8">
      <p className="text-3xl text-center">Cheap and simple devices we love.</p>
      <p className="text-xl text-center">If you are interested in purchasing a new, dedicated device, here's some of our favorites to use as a Caregiving Connect display.</p>
      <div className="flex flex-col md:flex-row justify-center">
          {/* Feature 1 */}
          <div className="text-left px-8 md:w-1/3 rounded-lg">
              <Tablet className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Android or Ipad Tablets</h3>
            <p className="text-md text-left">Space saving, touchscreen, wall-mountable, and portable are just some of the benefits of a tablet based display. These can also be cheap enough <a href="https://amzn.to/3VIMhR8" target="_blank" className="link">(under $50)</a> to try one out and then get a few more for the bedroom, kitchen, and next to the sofa.</p>
          </div>
          {/* Feature 2 */}
          <div className="text-left px-8 md:w-1/3 rounded-lg">
            <Monitor className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Touchscreen Laptops</h3>
            <p className="text-md text-left">Laptops offer a larger screen and a physical keyboard interface with the display. A touchscreen can make it easier to interact with the buttons on the display page but a track pad can also be used to click on the display. Because the processing requirements are minimal, a simple Chromebook makes a great, cost saving option.</p>
          </div>
          {/* Feature 3 */}
          <div className="text-left px-8 md:w-1/3 rounded-lg">
            <Monitor className="w-12 h-12 inline-block ml-1" />
            <h3 className="text-xl text-left font-semibold">Desktop Computers</h3>
            <p className="text-md text-left">
              Desktops often offer an even larger display as well as a mouse that may be easier to use for some than a track pad. 
            </p>
          </div>
        </div>
        <div id="infinite_timeout" className="pt-8">
      <p className="text-center text-3xl">Device settings</p>
        <p className="text-center text-xl py-2">Follow these tips to optimize any device to work well as an always-on display.</p>
      <div className="flex flex-col gap-2 max-w-lg mx-auto justify-center w-full">
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <ClockIcon className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Set Screen Timeout</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <p className="text-left indent-8">
              In order for the always-on display to stay always-on, some devices require an extra step. 
              If you find the display page is dimming or turning off after some time, you'll need to adjust the screen timeout to "never" in your device's settings. 
              Reach out to us at support@caregivingconnect.com and we can walk you through this for any device.
            </p>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row gap-2 items-center text-xl">
            <BellAlertIcon className="w-12 h-12 inline-block" />
            <h3 className="text-xl text-left font-semibold">Disable notifications</h3>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <p className="text-left indent-8">
              To keep the display as simple to use, you'll want to limit disruptions in the form of notifications from other apps.
              These can cause sounds and pop ups that could be distracting or obstruct the display. Caregiving Connect does not use
              notifications on the display page so it's simple and safe to just disable all notifications. This is usually done through
              the device's settings menu.
            </p>
            <p className="text-left indent-8">
              Another way to disable notifications less permanently if the device is also used for other tasks is enabling do not disturb.
              This setting can often be quickly toggled on in a quick menu or in the devices settings and should result in similar results
              to disabling notifications completely.
            </p>
          </div>
        </div>
        <div className="collapse bg-base-200">
          <input type="radio" name="my-accordion-1" /> 
          <div className="collapse-title flex flex-row items-center justify-between text-xl">
            <div className="flex flex-shrink-none flex-row items-center gap-2">
              <ArrowPathIcon className="w-12 h-12 inline-block" />
              <h3 className="text-xl text-left font-semibold">Disable auto update </h3>
            </div>
          </div>
          <div className="collapse-content list-disc pl-5"> 
            <p className="text-left indent-8">
              Keeping devices up to date is important for security and useability but unfortunately, auto update messages can pop up any time
              and obscure the display. These messages are often not limited like other notifications since they're handled more as more important
              system updates so we suggest disabling any auto-update or check for update features. You can still periodically check for updates
              manually through system settings but this should help prevent pop ups that could interfere with the display.
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    
  </div>
  );
}

export default DeviceOverviewPage;