import './App.css'
import React, {useEffect, useContext, Suspense, lazy} from 'react';
import {Routes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
// pages
import AboutPage from './Pages/AboutPage';
import PrivacyPage from './Pages/PrivacyPage';
import HomePage from './Pages/HomePage';
import AccountPage from './Pages/AccountPage';
import SignInPage from './Pages/SignInPage';
import FAQPage from './Pages/FAQPage';
import BlogPage from './Pages/BlogPage';
import AlexaTogetherArticle from './articles/AlexaTogetherArticle';
import AppOverviewPage from './Pages/AppOverviewPage';
import HowItWorksPage from './Pages/HowItWorksPage';
import TermsPage from './Pages/TermsPage';
import CheckoutSuccessPage from './Pages/CheckoutSuccessPage';
import CheckoutCancelPage from './Pages/CheckoutCancelPage';
// import OnboardingPage from './Pages/OnboardingPage';
// import DashboardPage from './Pages/DashboardPage';
// import DisplayPage from './Pages/DisplayPage'; // Import the DisplayPage component
// import PricingPage from './Pages/PricingPage';
import { Helmet } from 'react-helmet';
import { messaging } from './utils/firebase-config';
import { onMessage } from "firebase/messaging";
import CCLogo from './assets/CCLogo.png'
import { StateContext } from './stateContext';
import { useUser } from './utils/UserProvider'; // Adjust the import path to your UserProvider file
import apiClient from "./utils/apiClient"; // Adjust the import path as necessary
import local_storage from './utils/local_storage';
import ReactGA from "react-ga4";
import DisplayOverviewPage from './Pages/DisplayOverviewPage';
import DashboardOverviewPage from './Pages/DashboardOverviewPage';
import DeviceOverviewPage from './Pages/DeviceOverviewPage';
import DementiaClockArticle from './articles/DementiaClockArticle';
import ProfessionalsPage from './Pages/ProfessionalsPage';

const DashboardPage = lazy(() => import('./Pages/DashboardPage'));
const DisplayPage = lazy(() => import('./Pages/DisplayPage'));
const OnboardingPage = lazy(() => import('./Pages/OnboardingPage'));
const PricingPage = lazy(() => import('./Pages/PricingPage'));

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const hasLoggedIn = local_storage.getItem("hasLoggedIn")
  const {user, signOut, signIn, loading, profile, setProfile } = useUser();
  const MEASUREMENT_ID = "G-K1YFXSF99L"; // Replace with your GA4 Measurement ID

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const { data: serverVersion } = await apiClient.get('/getVersion');
        const localVersion = local_storage.getItem('appVersion');

        if (!localVersion || serverVersion !== localVersion) {
          local_storage.setItem('appVersion', serverVersion);
          window.location.reload(true);  // Force reload to fetch new files
        }
      } catch (error) {
        console.error('Failed to fetch version from server', error);
      }
    };

    checkVersion();
  }, []);

  useEffect(() => {
    ReactGA.initialize(MEASUREMENT_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

    const handleSignInOut = async () => {
    if (user) {
      await signOut()
      navigate("/")
    } else signIn()
  }

const NavbarAndFooter = React.memo(({ children }) => {
  const {setToastData, toastData, setNewMessage, setProfile} = useContext(StateContext);
    
     useEffect(() => {
    let timer;
    if (toastData.show) {
        timer = setTimeout(() => {
                  setToastData({...toastData,show: false});
        }, 5000);
    }
    return () => {
        if (timer) {
            clearTimeout(timer);
        }
    };
  }, [toastData.show]);

      // Set up message listener
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      // console.log('Message received in app.js. ', payload);
      setToastData({text:`Message received: ${payload?.data?.title}`, show:true})
      setNewMessage(payload)
        const { title, body, icon, url } = payload.data;

    // Show a notification at the document level
    if (Notification.permission === "granted") {
      const notification = new Notification(title, {
        body: body,
        icon: icon,
      });

      // Add click event to the notification
      notification.onclick = () => {
        window.open(url, '_blank'); // Open the URL on click
      };
    }

      // if (location.pathname.startsWith('/display')) setNewMessage(payload)
      // else console.log("not on display page so not setting new message")
    });

    return () => {
      unsubscribe();
    };
  }, []);

    useEffect(() => {
    if (!loading && !user && ['/dashboard', '/welcome', '/account'].includes(location.pathname) ) {
      // console.log("protected route, heading home")
      navigate("/")
    }
    // let the dashboard page send them back to the welcome page if not onboarded to reduce problems with runaway usestates
    // if (!loading && user && profile && !profile?.onboarded){
    //   // setProfile(profile)
    //   navigate("/welcome")
    // }
  }, [loading, user, location.pathname]);
    
    return (
      <div className="w-screen min-h-screen flex flex-col flex-grow items-stretch">
      <div className="top-0 sticky z-20 w-screen">
        {toastData.show && 
          <div className="toast toast-center z-30">
            <div className={`alert alert-${toastData.type} flex`}>
              <p className="text-center">{toastData.text}</p>
              {/* <button onClick={() => setToastData({...toastData, show:false})}>
                Close
              </button> */}
            </div>
          </div>}
            
          <div className="navbar bg-base-100 shadow-lg">
            <div className="navbar-start ">
              <div className="dropdown">
                <div tabIndex={0} role="button" className="btn btn-ghost md:hidden">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                </div>
                <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                  {!user && <li><p onClick={()=>navigate('/display')}>Try the Demo</p></li>}
                  {/* {['/dashboard', '/welcome', '/account'].includes(location.pathname) ?  */}
                  {user ? 
                  <li className="order-last">
                      {/*  mobile on any logged in page */}
                    <a className="hover:bg-inherit hover:pointer-events-none">Info</a>
                    <ul className="p-2">
                      {/* <li><p onClick={()=>navigate('/how-it-works')}>How it Works</p></li>   */}
                      <li className=""><p onClick={()=>navigate('/display-overview')}>The Display</p></li>
                      <li className=""><p onClick={()=>navigate('/dashboard-overview')}>The Dashboard</p></li>
                      <li className=""><p onClick={()=>navigate('/app-overview')}>Mobile App</p></li>
                      <li><p onClick={()=>navigate('/pricing')}>Pricing</p></li>  
                      <li><p onClick={()=>navigate('/frequent-questions')}>FAQ</p></li>  
                      <li><p onClick={()=>navigate('/blog')}>Blog</p></li>  
                      <li><p onClick={()=>navigate('/about')}>About Us</p></li>  
                      <li><p onClick={()=>navigate('/contact')}>Contact Us</p></li>  
                      <li><p onClick={()=>navigate('/professionals')}>Professionals</p></li>  
                      <li><p onClick={()=>navigate('/privacy')}>Privacy Policy</p></li>  
                      <li><p onClick={()=>navigate('/terms')}>Terms of Service</p></li>  
                    </ul>
                  </li>:
                  <>
                    {/* mobile on any public facing page */}
                    {/* <li><a href="/#families">Families</a></li>  
                    <li><p onClick={()=>navigate('#professionals')}>Professionals</p></li>   */}
                    <li className=""><p onClick={()=>navigate('/display-overview')}>The Display</p></li>
                    <li className=""><p onClick={()=>navigate('/dashboard-overview')}>The Dashboard</p></li>
                    <li><p onClick={()=>navigate('/pricing')}>Pricing</p></li>  
                    <li><p onClick={()=>navigate('/how-it-works')}>How it Works</p></li>
                    <li className=""><p onClick={()=>navigate('/app-overview')}>Mobile App</p></li>
                    <li><p onClick={()=>navigate('/frequent-questions')}>FAQ</p></li>  
                    {!user && <li><p onClick={()=>navigate('/login')}>{hasLoggedIn ? "Log In" : "Sign Up"}</p></li>}
                    <li>
                      <a className="hover:bg-inherit hover:pointer-events-none">Info</a>
                      <ul className="p-2">
                        <li><p onClick={()=>navigate('/blog')}>Blog</p></li>  
                        <li><p onClick={()=>navigate('/about')}>About Us</p></li>  
                        <li><p onClick={()=>navigate('/contact')}>Contact Us</p></li>  
                        <li><p onClick={()=>navigate('/professionals')}>Professionals</p></li>  
                        <li><p onClick={()=>navigate('/privacy')}>Privacy Policy</p></li>  
                        <li><p onClick={()=>navigate('/terms')}>Terms of Service</p></li>  
                      </ul>
                    </li>
                    </>}
                  {user && 
                  <li>
                    {/* mobile logged in */}
                    <a className="hover:bg-inherit hover:pointer-events-none">Menu</a>
                    <ul className="p-2">
                      <li><p onClick={()=>navigate('/dashboard')}>My Dashboard</p></li>
                      <li><p onClick={()=>navigate('/account')}>My Account</p></li>
                      {/* <li><p onClick={()=>navigate('/display')}>Display</p></li> */}
                      <li><p onClick={handleSignInOut}>Log Out</p></li>
                    </ul>
                  </li>}
                  </ul>
              </div>
                <button className="hidden md:btn md:btn-ghost z-10"  onClick={()=>navigate('/')} title="Home">
                  <img className="w-8" alt="Caregiving Connect logo" src={CCLogo}></img>
                  <p className="hidden xl:block xl:text-xl">Caregiving Connect</p>
                </button>
              </div>
            <div className="navbar-center flex md:hidden">
              <button className="btn btn-ghost z-10" title="Home">
                <img className="w-10" alt="Caregiving Connect logo" src={CCLogo}></img>
                <p className="text-lg md:text-xl" onClick={()=>navigate('/')}>Caregiving Connect</p>
              </button>
            </div>
            <div className="navbar-center hidden md:flex">
              {user ? 
              <ul className="menu menu-horizontal px-1">
                <li><p onClick={()=>navigate('/dashboard')}>My Dashboard</p></li>
                <li><p onClick={()=>navigate('/account')}>My Account</p></li>
                <li className=""><p onClick={()=>navigate('/pricing')}>Pricing</p></li>
                <li className=""><p onClick={()=>navigate('/frequent-questions')}>FAQ</p></li>
                <li className=""><p onClick={()=>navigate('/app-overview')}>Mobile App</p></li>
                  <li className="">
                    <details>
                    <summary>Info</summary>
                    <ul className="p-2 w-48">
                      <li className=""><p onClick={()=>navigate('/display-overview')}>The Display</p></li>
                      <li className=""><p onClick={()=>navigate('/dashboard-overview')}>The Dashboard</p></li>
                      <li className=""><p onClick={()=>navigate('/how-it-works')}>How it works</p></li>
                      <li className=""><p onClick={()=>navigate('/pricing')}>Pricing</p></li>  
                      <li className=""><p onClick={()=>navigate('/frequent-questions')}>FAQ</p></li>
                      <li className=""><p onClick={()=>navigate('/app-overview')}>Mobile App</p></li>
                      <li><p onClick={handleSignInOut}>Log out</p></li>
                    </ul>
                    </details>
                  </li>
                  </ul>
              :
              <ul className="menu menu-horizontal px-1">
                {/* {!user && <li><p onClick={() => window.open('/display/demo', '_blank')}>Demo</p></li>} */}
                {/* <li className=""><a href="/#families">Families</a></li>
                <li className=""><p onClick={()=>navigate('/#professionals')}>Professionals</p></li> */}
                {/* <li className="hidden md:block"><p onClick={()=>navigate('/how-it-works')}>How it works</p></li> */}
                <li className="hidden md:block"><p onClick={()=>navigate('/display-overview')}>The Display</p></li>
                <li className="hidden md:block"><p onClick={()=>navigate('/dashboard-overview')}>The Dashboard</p></li>
                <li className="hidden md:block"><p onClick={()=>navigate('/how-it-works')}>How it works</p></li>
                <li className="hidden md:block"><p onClick={()=>navigate('/pricing')}>Pricing</p></li>
                <li className="hidden lg:block"><p onClick={()=>navigate('/frequent-questions')}>FAQ</p></li>
                <li className="hidden lg:block"><p onClick={()=>navigate('/app-overview')}>Mobile App</p></li>
                  <li className="lg:hidden">
                    <details>
                    <summary>More</summary>
                    <ul className="p-2 w-48">
                      {/* {location.pathname!==('/') && <li><p onClick={()=>navigate('/')}>Home</p></li>} */}
                      <li className="md:hidden"><p onClick={()=>navigate('/how-it-works')}>How it works</p></li>
                      <li className="md:hidden"><p onClick={()=>navigate('/pricing')}>Pricing</p></li>  
                      <li className="lg:hidden"><p onClick={()=>navigate('/frequent-questions')}>FAQ</p></li>
                      <li className="lg:hidden"><p onClick={()=>navigate('/app-overview')}>Mobile App</p></li>
                    </ul>
                    </details>
                  </li>
                  {user ? 
                  <li>
                    <details>
                      <summary>Menu</summary>
                      <ul className="p-2 w-40">
                        <li><p onClick={()=>navigate('/dashboard')}>My Dashboard</p></li>
                        <li><p onClick={()=>navigate('/account')}>My Account</p></li>
                        {/* <li><p onClick={()=>navigate('/display')}>Display</p></li> */}
                        <li><p onClick={handleSignInOut}>Log Out</p></li>
                      </ul>
                    </details>
                  </li>:
                  <li><p onClick={()=>navigate('/login')}>{hasLoggedIn ? "Log In" : "Sign Up"}</p></li>
                  }
                  </ul>
                }
                  </div>
                  <div className="navbar-end ">
                    <p className="hidden md:btn md:btn-ghost " onClick={() => window.open('/display', '_blank')}>Display</p>
                  </div>
                  {/* <div className="navbar-end flex md:hidden">
                    <button className="btn btn-ghost z-10" title="Home">
                      <img className="w-10" alt="Caregiving Connect logo" src={CCLogo}></img>
                      <p className="text-lg md:text-xl" onClick={()=>navigate('/')}>Caregiving Connect</p>
                    </button>
                  </div> */}
                </div>
                </div>
                  <main className="flex flex-row w-screen flex-grow">
                    {children}
                  </main>
              <footer className="footer hidden md:flex p-4 bg-base-200 text-base-content flex-row justify-evenly items-start sm:items-center">
                <aside>
                    <div className="flex flex-col sm:flex-row gap-4">
                      <img className="w-10" alt="Caregiving Connect logo" src={CCLogo}></img>
                      <div className="flex flex-col">
                        <p className="">Copyright Caregiving Connect © 2024</p>
                        <p>All rights reserved</p>
                      </div>
                    </div>
                </aside> 
                {/* <nav>
                  <h6 className="footer-title">Services</h6> 
                  <a className="link link-hover">Branding</a>
                  <a className="link link-hover">Design</a>
                  <a className="link link-hover">Marketing</a>
                  <a className="link link-hover">Advertisement</a>
                </nav>  */}
                <nav>
                  <h6 className="footer-title">Company</h6> 
                  <p className="link link-hover" onClick={()=>navigate('/about')}>About Us</p>
                  <p className="link link-hover" onClick={()=>navigate('/blog')}>Blog</p>
                  {/* <a className="link link-hover">Contact</a> */}
                </nav> 
                <nav>
                  <h6 className="footer-title"></h6> 
                  {/* <p className="link link-hover" onClick={()=>navigate('/about')}>About Us</p> */}
                  <p className="link link-hover" onClick={()=>navigate('/professionals')}>Professionals</p>
                  <a className="link link-hover">Contact</a>
                </nav> 
                <nav>
                  <h6 className="footer-title">Legal</h6> 
                      <p className="link link-hover" onClick={()=>navigate('/privacy-policy')}>Privacy Policy</p>
                      <p className="link link-hover" onClick={()=>navigate('/terms')}>Terms of Service</p>
                  {/* <a className="link link-hover">Cookie policy</a> */}
                </nav>
              </footer>
              </div>)
          })

  return (
  <div data-theme='light' className="h-full">
    <Helmet>
      <link rel="canonical" href={`https://caregivingconnect.com${location.pathname}`} />
    </Helmet>
    <Suspense> {/* looks better without a fallback */}
      <Routes>
        <Route path="/" element={<NavbarAndFooter><HomePage/></NavbarAndFooter>} />
        <Route path="/about" element={<NavbarAndFooter><AboutPage/></NavbarAndFooter>} />
        <Route path="/professionals" element={<NavbarAndFooter><ProfessionalsPage/></NavbarAndFooter>} />
        <Route path="/blog" element={<NavbarAndFooter><BlogPage/></NavbarAndFooter>} />
        <Route path="/blog/goodbye-alexa-together" element={<NavbarAndFooter><AlexaTogetherArticle/></NavbarAndFooter>} />
        <Route path="/blog/dementia-clocks" element={<NavbarAndFooter><DementiaClockArticle/></NavbarAndFooter>} />
        <Route path="/welcome" element={<NavbarAndFooter><OnboardingPage/></NavbarAndFooter>} />
        <Route path="/account" element={<NavbarAndFooter><AccountPage/></NavbarAndFooter>} />
        <Route path="/login" element={<NavbarAndFooter><SignInPage/></NavbarAndFooter>}/>
        <Route path="/about" element={<NavbarAndFooter><AboutPage/></NavbarAndFooter>} />
        <Route path="/app-overview" element={<NavbarAndFooter><AppOverviewPage/></NavbarAndFooter>} />
        <Route path="/dashboard-overview" element={<NavbarAndFooter><DashboardOverviewPage/></NavbarAndFooter>} />
        <Route path="/devices" element={<NavbarAndFooter><DeviceOverviewPage/></NavbarAndFooter>} />
        <Route path="/display-overview" element={<NavbarAndFooter><DisplayOverviewPage/></NavbarAndFooter>} />
        <Route path="/frequent-questions" element={<NavbarAndFooter><FAQPage/></NavbarAndFooter>} />
        <Route path="/how-it-works" element={<NavbarAndFooter><HowItWorksPage/></NavbarAndFooter>} />
        <Route path="/pricing" element={<NavbarAndFooter><PricingPage/></NavbarAndFooter>} />
        <Route path="/dashboard" element={<NavbarAndFooter><DashboardPage/></NavbarAndFooter>} />
        <Route path="/terms" element={<NavbarAndFooter><TermsPage/></NavbarAndFooter>} />
        <Route path="/checkout-success" element={<NavbarAndFooter><CheckoutSuccessPage/></NavbarAndFooter>} />
        <Route path="/checkout-cancelled" element={<NavbarAndFooter><CheckoutCancelPage/></NavbarAndFooter>} />
        <Route path="/privacy-policy" element={<NavbarAndFooter><PrivacyPage/></NavbarAndFooter>} />
        <Route path="/display/:code?" element={<DisplayPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  </div>
  );
}

export default App;